import { useInfiniteQuery } from "@tanstack/react-query";
import QueryString from "qs";
import { StrapiResponse, StrapiSubCategory } from "~/shared-types";
import { strapiClient as defaultStrapiClient } from "~/strapi/api";
import {
  QueryFunctionOptions,
  getNextPageParam,
  getQueryObject,
} from "./shared";

export const CATEGORIES_QUERY_KEY = "categories";

export const getSubCategories = async (options: QueryFunctionOptions = {}) => {
  const { strapiClient = defaultStrapiClient } = options;

  const queryObject = getQueryObject(options);
  const queryObjectWithMainCategory = {
    ...queryObject,
    populate: [
      "mainCategory",
      "mainCategory.image",
      "mainCategory.icon",
      "image",
    ],
  };
  const query = QueryString.stringify(queryObjectWithMainCategory);

  const { data } = await strapiClient.get<StrapiResponse<StrapiSubCategory[]>>(
    "/sub-categories?" + query
  );

  return data;
};

export const useSubCategoriesQuery = (
  options: QueryFunctionOptions = {},
  initialData?: StrapiResponse<StrapiSubCategory[]>
) => {
  const queryObject = getQueryObject(options);
  const query = QueryString.stringify(queryObject);

  return useInfiniteQuery({
    queryKey: [CATEGORIES_QUERY_KEY, query],
    queryFn: async ({ pageParam = 1 }) =>
      getSubCategories({ ...options, pageParam }),
    getNextPageParam,
    initialPageParam: initialData ? 1 : undefined,
    initialData: {
      pages: [initialData],
      pageParams: [initialData ? 1 : undefined],
    },
  });
};
