import { useOrdersQuery } from "~/api/orders";
import { Badge } from "../Badge";
import { HamburgerMenu } from "../HamburgerMenu";

export const MyOrdersLink = () => {
  const { data: orders } = useOrdersQuery();
  const activeOrders = orders.pages
    .flatMap((page) => page?.data ?? [])
    .filter(
      (order) =>
        order.attributes.status === "initial" ||
        order.attributes.status === "paid"
    );

  return (
    <HamburgerMenu.Link href="/backroom/orders" icon="packageDependents">
      Mine ordrer
      {activeOrders.length > 0 ? (
        <Badge color="red" className="ml-auto">
          {activeOrders.length}
        </Badge>
      ) : null}
    </HamburgerMenu.Link>
  );
};
