import { StrapiSubCategory } from "~/shared-types";
import MobileNavBar from "./MobileNavBar";
import DesktopNavBar from "./DesktopNavBar";
import { useSubCategoriesQuery } from "~/api/categories";
import { getGroupedCategories } from "~/helpers/getGroupedCategories";
import { useEffect } from "react";
import { useWithFetchAll } from "~/api/useWithFetchAll";

export const NavBar = () => {
  const { data, fetchAllPages } = useWithFetchAll(
    useSubCategoriesQuery({ sortKey: "name", sortDirection: "asc" })
  );
  useEffect(() => {
    fetchAllPages();
  }, []);

  const categories = data?.pages
    .flatMap((page) => page?.data)
    .filter(Boolean) as StrapiSubCategory[];
  const groupedCategories = getGroupedCategories(categories);

  return (
    <>
      <MobileNavBar categories={groupedCategories} />
      <DesktopNavBar categories={groupedCategories} />
    </>
  );
};
