import classNames from "classnames";
import Link from "next/link";
import { UrlObject } from "url";

type Props = {
  className?: string;
  size?: "full" | "icon";
  openNewTab?: boolean;
  href?: string | UrlObject;
};

export const Logo = ({ className, size = "full", openNewTab, href }: Props) => {
  return (
    <Link
      className={className}
      href={href ?? "/"}
      target={openNewTab ? "_blank" : "_self"}
      aria-label="Butik Danmark"
    >
      <span
        className={classNames(
          "block",
          size === "full" ? "w-[149px]" : "w-10",
          "max-w-full"
        )}
      >
        {size === "full" ? (
          <FullLogo className="text-primary-500" />
        ) : (
          <IconLogo className="text-primary-500" />
        )}
      </span>
    </Link>
  );
};

const FullLogo = ({ className }: { className?: string }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 289 95.2"
      enableBackground={"new 0 0 289 95.2;"}
      xmlSpace="preserve"
      className={className}
    >
      <g>
        <path
          fill="currentColor"
          d="M105.8,84.7V52.9h9.5c3.5,0,6.6,0.5,9.1,1.6c2.5,1.1,4.5,2.8,5.9,5.2c1.4,2.4,2.1,5.4,2.1,9
			c0,3.2-0.6,6.1-1.8,8.4s-3,4.2-5.4,5.5c-2.4,1.3-5.3,1.9-8.8,1.9H105.8z M115.8,81.2c2.6,0,4.8-0.4,6.6-1.3c1.8-0.8,3.2-2.2,4.2-4
			c1-1.8,1.5-4.2,1.5-7.1c0-2.7-0.4-5-1.3-6.8c-0.9-1.8-2.2-3.2-4.1-4.2c-1.8-1-4.2-1.4-7-1.4h-5.7v24.8H115.8z"
        />
        <path
          fill="currentColor"
          d="M146.7,85.3c-1.9,0-3.7-0.5-5.2-1.4c-1.5-0.9-2.7-2.2-3.6-4c-0.9-1.8-1.3-3.9-1.3-6.3c0-2.2,0.4-4.1,1.2-5.9
			c0.8-1.8,2-3.2,3.6-4.2c1.5-1,3.3-1.5,5.4-1.5c1.7,0,3.1,0.4,4.3,1.1s2.1,1.6,2.7,2.6c0-0.3,0-0.6,0-0.9c0-0.3,0-0.6,0-0.9v-1.2
			h3.9v21.9h-3.9v-1.2c0-0.3,0-0.6,0-0.9c0-0.3,0-0.6,0-0.9c-0.6,1.1-1.6,2-2.8,2.7C149.7,85,148.3,85.3,146.7,85.3z M147.4,82.3
			c1.3,0,2.5-0.4,3.5-1c1-0.7,1.8-1.7,2.4-3c0.6-1.3,0.8-2.8,0.8-4.6c0-2.6-0.6-4.7-1.8-6.2c-1.2-1.5-2.8-2.3-4.8-2.3
			c-1.3,0-2.5,0.4-3.5,1.1c-1,0.7-1.8,1.7-2.4,3c-0.6,1.3-0.9,2.8-0.9,4.5c0,1.7,0.3,3.2,0.8,4.5c0.6,1.3,1.3,2.3,2.4,3
			C144.9,81.9,146,82.3,147.4,82.3z"
        />
        <path
          fill="currentColor"
          d="M164.4,84.7V62.8h3.9v1.5c0,0.1,0,0.3,0,0.4c0,0.1,0,0.3,0,0.4c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3
			c0.6-1,1.5-1.9,2.7-2.6c1.2-0.7,2.5-1.1,4-1.1c1.7,0,3.1,0.3,4.3,1c1.2,0.7,2.1,1.7,2.8,3c0.7,1.3,1,2.9,1,4.8v13.8h-3.9V71.4
			c0-2.1-0.4-3.7-1.1-4.7c-0.8-1-2-1.5-3.7-1.5c-1.2,0-2.3,0.3-3.2,0.9c-0.9,0.6-1.6,1.3-2.1,2.3c-0.5,1-0.7,2-0.7,3.2v13.2H164.4z"
        />
        <path
          fill="currentColor"
          d="M189.5,84.7V62.8h3.9v1.7c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.6c0.7-1.1,1.6-2,2.7-2.7c1.1-0.7,2.3-1,3.7-1
			c1.5,0,2.8,0.4,3.9,1.1c1.1,0.7,2,1.8,2.7,3.2c0.8-1.3,1.8-2.4,3-3.2c1.2-0.8,2.6-1.2,4.1-1.2s2.8,0.3,4,1s2,1.6,2.7,2.9
			c0.6,1.3,1,2.9,1,4.9v13.8h-3.9V71.6c0-2.1-0.4-3.8-1.1-4.8c-0.7-1.1-1.9-1.6-3.5-1.6c-1.1,0-2.1,0.3-2.9,0.9
			c-0.8,0.6-1.4,1.4-1.9,2.4c-0.4,1-0.6,2.1-0.6,3.2v13h-3.9V71.7c0-2.1-0.4-3.8-1.1-4.9c-0.7-1.1-1.9-1.7-3.4-1.7
			c-1.1,0-2,0.3-2.9,0.9s-1.5,1.4-1.9,2.4c-0.5,1-0.7,2.1-0.7,3.3v12.8H189.5z"
        />
        <path
          fill="currentColor"
          d="M236.3,85.3c-1.9,0-3.7-0.5-5.2-1.4c-1.5-0.9-2.7-2.2-3.6-4c-0.9-1.8-1.3-3.9-1.3-6.3c0-2.2,0.4-4.1,1.2-5.9
			c0.8-1.8,2-3.2,3.6-4.2c1.5-1,3.3-1.5,5.4-1.5c1.7,0,3.1,0.4,4.3,1.1s2.1,1.6,2.7,2.6c0-0.3,0-0.6,0-0.9c0-0.3,0-0.6,0-0.9v-1.2
			h3.9v21.9h-3.9v-1.2c0-0.3,0-0.6,0-0.9c0-0.3,0-0.6,0-0.9c-0.6,1.1-1.6,2-2.8,2.7C239.3,85,237.9,85.3,236.3,85.3z M237,82.3
			c1.3,0,2.5-0.4,3.5-1c1-0.7,1.8-1.7,2.4-3c0.6-1.3,0.8-2.8,0.8-4.6c0-2.6-0.6-4.7-1.8-6.2c-1.2-1.5-2.8-2.3-4.8-2.3
			c-1.3,0-2.5,0.4-3.5,1.1c-1,0.7-1.8,1.7-2.4,3c-0.6,1.3-0.9,2.8-0.9,4.5c0,1.7,0.3,3.2,0.8,4.5c0.6,1.3,1.3,2.3,2.4,3
			C234.5,81.9,235.6,82.3,237,82.3z"
        />
        <path
          fill="currentColor"
          d="M254,84.7V62.8h3.9v1c0,0.2,0,0.5,0,0.7s0,0.5,0,0.7v1.3c0.6-1.3,1.5-2.4,2.7-3.2c1.2-0.8,2.6-1.2,4.2-1.2
			c0.4,0,0.8,0,1,0V66c-0.4-0.1-0.8-0.1-1.2-0.1c-1.4,0-2.6,0.3-3.6,0.8s-1.7,1.3-2.2,2.4s-0.8,2.4-0.8,4v11.6H254z"
        />
        <path
          fill="currentColor"
          d="M269.8,84.7V52.9h3.9v19.2c0.5-0.6,1.1-1.2,1.7-1.7l7.4-7.6h4.9l-14.1,13.7v8.1H269.8z M283.7,84.7l-8.6-10.5
			l2.7-2.6L289,84.7H283.7z"
        />
      </g>
      <g>
        <path
          fill="currentColor"
          d="M50.1,47.6c8.8-4.3,13.4-12.1,13.4-22.5C63.5,12,56.2,2.9,42.3,0v44.9v5.4v44.9c13.9-2.9,21.2-12,21.2-25.1
			C63.5,59.7,58.9,51.9,50.1,47.6z"
        />
        <rect x="0" fill="currentColor" width="21.2" height="95.2" />
      </g>
      <g>
        <path
          fill="currentColor"
          d="M105.8,42.3V10.6h12.4c3.1,0,5.5,0.7,7.3,2c1.8,1.3,2.7,3.3,2.7,6c0,1.7-0.5,3.1-1.5,4.4
			c-1,1.3-2.3,2.1-3.8,2.6c1.9,0.6,3.4,1.6,4.5,2.9c1.1,1.3,1.6,3,1.6,4.9c0,2.2-0.5,4-1.6,5.3c-1.1,1.3-2.5,2.2-4.3,2.8
			c-1.8,0.5-3.8,0.8-6.1,0.8H105.8z M117.6,24c1.4,0,2.5-0.2,3.5-0.6c1-0.4,1.7-1,2.2-1.7c0.5-0.8,0.7-1.7,0.7-2.7
			c0-1.5-0.5-2.7-1.5-3.6c-1-0.9-2.5-1.3-4.6-1.3h-8V24H117.6z M117.7,38.8c2.4,0,4.2-0.5,5.4-1.4c1.1-0.9,1.7-2.4,1.7-4.3
			c0-1.8-0.6-3.2-1.7-4.2c-1.1-1-3-1.5-5.6-1.5h-7.6v11.4H117.7z"
        />
        <path
          fill="currentColor"
          d="M142.3,43c-1.7,0-3.1-0.3-4.3-1c-1.2-0.7-2.1-1.6-2.7-2.9c-0.6-1.3-0.9-2.8-0.9-4.7V20.5h3.9v13.1
			c0,2.1,0.4,3.8,1.2,4.8c0.8,1.1,2.1,1.6,3.8,1.6c1.2,0,2.2-0.3,3-0.9c0.8-0.6,1.5-1.4,1.9-2.5c0.4-1.1,0.7-2.4,0.7-4V20.5h3.9
			v21.9H149V41c0-0.3,0-0.6,0-0.9c0-0.3,0-0.6,0-0.8c-0.5,1.1-1.4,2.1-2.6,2.7C145.2,42.7,143.8,43,142.3,43z"
        />
        <path
          fill="currentColor"
          d="M167.2,42.6c-1.8,0-3.3-0.5-4.5-1.5c-1.2-1-1.7-2.7-1.7-4.9V23.7h-4.1v-3.2h4.1v-7.2h3.9v7.2h4.5v3.2h-4.5v12
			c0,1.2,0.3,2.1,0.8,2.6c0.6,0.5,1.4,0.8,2.4,0.8c0.5,0,1,0,1.6-0.1v3.4c-0.4,0.1-0.8,0.2-1.2,0.2
			C168.1,42.5,167.7,42.6,167.2,42.6z"
        />
        <path
          fill="currentColor"
          d="M174.1,16.3v-4.7h4.5v4.7H174.1z M174.4,42.3V20.5h3.9v21.9H174.4z M174.4,23.7v-3.2h2v3.2H174.4z
			 M174.4,42.3v-3.2h3.9v3.2H174.4z"
        />
        <path
          fill="currentColor"
          d="M185,42.3V10.6h3.9v19.2c0.5-0.6,1.1-1.2,1.7-1.7l7.4-7.6h4.9l-14.1,13.7v8.1H185z M198.9,42.3l-8.6-10.5
			l2.7-2.6l11.2,13.1H198.9z"
        />
      </g>
    </svg>
  );
};

const IconLogo = ({ className }: { className?: string }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 144 216"
      enableBackground={"new 0 0 144 216;"}
      xmlSpace="preserve"
      className={className}
    >
      <g>
        <path
          fill="currentColor"
          d="M113.6,108c19.9-9.7,30.4-27.4,30.4-51c0-29.8-16.6-50.5-48-57v101.9v12.2V216c31.4-6.6,48-27.2,48-57
			C144,135.4,133.5,117.7,113.6,108z"
        />
        <rect fill="currentColor" width="48" height="216" />
      </g>
    </svg>
  );
};
