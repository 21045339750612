import { ROUTES, Route } from "~/routes";

type Destination = Route["destination"];
export type ReturnedRoute = {
  pathname: Route["source"];
  query?: Record<string, string | number> | null;
};

const replaceColonWithBrackets = (route: string) => {
  return route.replace(/:(\w+)/g, "[$1]");
};

/**
 *
 * @param destinationRoute The page folder to which you want to navigate
 * @param params Params that fit into a dynamic route
 * @param options Object with query params
 *
 * @returns The route to the page with the params inserted
 */
export const getRoute = (
  destinationRoute: Destination,
  params?: Record<string, string | number> | null,
  options?: { query?: Record<string, string | number> }
): ReturnedRoute => {
  const route = ROUTES.find((r) => r.destination === destinationRoute);

  if (!route) {
    throw new Error(
      `Route "${destinationRoute}" not found. Available routes are: ${ROUTES.map(
        (r) => r.destination
      ).join(" | ")}. Add new routes in routes.js`
    );
  }

  // Replace the colon params with brackets, so that the path can be used with next/link
  // Example: /categories/:name => /categories/[name]
  const pathWithBracketParams = replaceColonWithBrackets(route.source);

  // Convert all params to lowercase, so that the path can be used with next/link
  const lowerCaseParams = Object.entries(params ?? {}).reduce(
    (acc, [key, value]) => {
      acc[key] = String(value).toLowerCase();
      return acc;
    },
    {} as Record<string, string>
  );

  return {
    pathname: pathWithBracketParams as Route["source"],
    query: {
      ...lowerCaseParams,
      ...options?.query,
    },
  };
};
