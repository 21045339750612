import { StrapiStore } from "~/shared-types";
import {
  Inputs,
  prepareDefaultValues,
  editStoreFormSchema,
} from "./useStoreForm";
import { ZodIssue } from "zod";
import { strapiClient } from "~/strapi/api";

export const checkValidForm = (values: Partial<Inputs>) => {
  const validation: {
    isValid: boolean;
    errors: Array<{ field: keyof Inputs; error: string }>;
  } = { isValid: true, errors: [] };

  try {
    editStoreFormSchema.parse(values);
  } catch (errors) {
    const zodError = errors as { issues: ZodIssue[] };

    zodError.issues.forEach((error) => {
      const field = error.path[0].toString() as keyof Inputs;
      const errorMessage = error.message;

      validation.errors.push({
        field,
        error: errorMessage,
      });
    });
    validation.isValid = false;
  }

  if (values.deliveryType === "selfManaged") {
    if (!values.deliveryPrice) {
      validation.errors.push({
        field: "deliveryPrice",
        error: "Standard fragtpris skal udfyldes",
      });
      validation.isValid = false;
    }
  }

  if (values.deliveryType === "shipmondo") {
    if (!values.shipmondoUsername) {
      validation.errors.push({
        field: "shipmondoUsername",
        error: "Shipmondo-bruger skal udfyldes",
      });
      validation.isValid = false;
    }

    if (!values.shipmondoPassword) {
      validation.errors.push({
        field: "shipmondoPassword",
        error: "Shipmondo-nøgle skal udfyldes",
      });
      validation.isValid = false;
    }
  }

  if (values.deliveryType !== "none" && values.offersFreeDelivery === "yes") {
    if (!values.freeDeliveryPurchasePrice) {
      validation.errors.push({
        field: "freeDeliveryPurchasePrice",
        error: "Pris for fri fragt skal udfyldes",
      });
      validation.isValid = false;
    }
  }

  if (
    values.acceptsPickUp === "yes" &&
    values.sameAddress === "no" &&
    !values.shownAddress
  ) {
    validation.errors.push({
      field: "shownAddress",
      error: "Adresse skal udfyldes",
    });
    validation.isValid = false;
  }

  return validation;
};

export const checkValidStore = async (storeId: number) => {
  const { data } = await strapiClient.get<{ data: StrapiStore }>(
    `/stores/${storeId}?populate=*`
  );

  const formValues = prepareDefaultValues(data.data);

  return checkValidForm(formValues);
};
