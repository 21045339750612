import Script from "next/script";
import { RefObject } from "react";

export const BannerScript = () => {
  if (process.env.NODE_ENV !== "production") return null;

  return (
    <Script
      async
      id="Cookiebot"
      src="https://consent.cookiebot.com/uc.js"
      data-cbid="5ebbfba1-f7f5-4ae3-a43f-2a5d411308cf"
      data-blockingmode="auto"
      type="text/javascript"
      strategy="afterInteractive"
    />
  );
};

export const DeclarationScript = ({
  parentRef,
}: {
  parentRef: RefObject<HTMLElement>;
}) => {
  function moveScript(e: Event) {
    parentRef.current?.appendChild(e.target as Node);
  }

  return (
    <Script
      async
      id="CookieDeclaration"
      src="https://consent.cookiebot.com/5ebbfba1-f7f5-4ae3-a43f-2a5d411308cf/cd.js"
      type="text/javascript"
      strategy="afterInteractive"
      onLoad={moveScript}
    />
  );
};
