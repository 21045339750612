import { Inter } from "@next/font/google";
import classNames from "classnames";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Footer } from "~/components/Footer";
import { NavBar } from "~/components/NavBar";
import { LayoutProvider, useLayout } from "./layoutContext";

import styles from "./layout.module.scss";
import { NavigationBar } from "~/components/Backroom/NavigationBar";
import { ROUTES, Route } from "~/routes";

const inter = Inter({ subsets: ["latin"] });

export function compareRoutes(route: Route, pathname: string): boolean {
  // If it's a direct match, return true.
  if (route.destination === pathname) return true;

  // Convert the route's destination pattern into a regex pattern.
  // Converts "/backroom/orders/:id/edit" to "^/backroom/orders/([^/]+)/edit$"
  const regexPattern =
    "^" + route.destination.replace(/:[^/]+/g, "([^/]+)") + "$";

  // Test the pathname against the generated regex.
  const regex = new RegExp(regexPattern);
  return regex.test(pathname);
}

export const LayoutedMain = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = useRouter();
  const { setIsFullScreen, isFullScreen } = useLayout();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const backroomNavRoute = ROUTES.find((route) =>
      compareRoutes(route, pathname)
    );

    const isFullScreen = Boolean(
      backroomNavRoute &&
        "fullScreen" in backroomNavRoute &&
        backroomNavRoute.fullScreen
    );

    setIsFullScreen(isFullScreen);

    // this will fade in the page, so we don't see the flash from the fullscreen layout
    setIsMounted(true);
  }, [pathname]);

  return (
    <main
      className={classNames(
        inter.className,
        styles.main,
        isFullScreen && styles.fullScreen,
        isMounted ? styles.mounted : styles.unmounted
      )}
    >
      {children}
    </main>
  );
};

export const getRelevantNavBar = (currentNavbar?: Route["currentNavbar"]) => {
  if (currentNavbar === "backroom") return <NavigationBar />;

  if (currentNavbar === "default") return <NavBar />;

  return null;
};

export const getRelevantFooter = (currentFooter?: Route["currentFooter"]) => {
  if (currentFooter === "default") return <Footer />;

  return null;
};

export const Layout = ({
  children, // will be a page or nested layout
  routes = ROUTES,
}: {
  children: React.ReactNode;
  routes?: typeof ROUTES;
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { pathname } = useRouter();
  const currentRoute = routes.find((route) => compareRoutes(route, pathname));

  return (
    <>
      {getRelevantNavBar(currentRoute?.currentNavbar)}
      <LayoutProvider value={{ isFullScreen, setIsFullScreen }}>
        <LayoutedMain>{children}</LayoutedMain>
      </LayoutProvider>
      {getRelevantFooter(currentRoute?.currentFooter)}
    </>
  );
};
