import IconPath, { IconType } from "./iconPaths";

export type IconProps = {
  name: IconType;
  className?: string;
  title?: string;
  viewBox?: string;
  testId?: string;
};

function Icon({ name, className, title, viewBox, testId }: IconProps) {
  if (name === "pinOutline" || name === "triangleDown") viewBox = "0 0 12 12";
  if (
    name === "uploadCloud" ||
    name === "warning" ||
    name === "success" ||
    name === "danger" ||
    name === "info"
  )
    viewBox = "0 0 48 48";
  if (name === "packageDependents" || name === "lamp" || name === "storeAlt")
    viewBox = "0 0 36 36";
  if (name === "union") viewBox = "0 0 21 20";
  if (name === "pin") viewBox = "0 0 14 17";
  if (name === "moreCarts") viewBox = "0 0 33 24";
  if (name === "moreCartsIllustration") viewBox = "0 0 135 39";
  if (name === "lock") viewBox = "0 0 270 270";
  if (name === "moreCartsIllustration") className = "w-[135px] h-[39px]";
  if (name === "navigation") viewBox = "0 0 18 18";
  if (name === "copy") viewBox = "0 0 20 23";
  if (name === "phone") viewBox = "0 0 22 21";
  if (name === "mail") viewBox = "0 0 19 14";
  if (name === "link") viewBox = "0 0 24 19";
  if (name === "share") viewBox = "0 0 19 22";
  if (name === "download") viewBox = "0 0 24 24";
  if (name === "shippingFront") viewBox = "0 0 20 20";

  return (
    <svg
      data-testid={testId}
      viewBox={viewBox ?? "0 0 24 24"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? "w-6 h-6"}
    >
      {title && <title>{title}</title>}
      <IconPath name={name} />
    </svg>
  );
}

export default Icon;
