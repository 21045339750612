import React, {
  ForwardedRef,
  HTMLAttributeAnchorTarget,
  forwardRef,
} from "react";
import classNames from "classnames";
import { Variant } from "~/const/variant";
import { Size } from "~/const/size";
import "./index.scss";
import { ReturnedRoute } from "~/utils/getRoute";
import Link from "next/link";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: Size;
  variant?:
    | Variant
    | "basic"
    | "secondary"
    | "primary-dark"
    | "primary-light"
    | "disabled"
    | "ghost";
  href?: ReturnedRoute | string;
  target?: HTMLAttributeAnchorTarget;
}

const Button = forwardRef(
  (props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>): JSX.Element => {
    const { size, variant, className, href, target, ...rest } = props;

    const usedClassName = classNames(
      "Button",
      {
        [`Button--${size}`]: size,
        [`Button--${variant}`]: variant,
      },
      href && "text-center",
      className
    );

    if (href) {
      return (
        <Link
          target={target}
          href={href}
          className={usedClassName}
          children={rest.children}
        />
      );
    }

    return <button className={usedClassName} {...rest} ref={ref} />;
  }
);

export default Button;
