import { StrapiProduct } from "~/shared-types";
import { Badge } from "../Backroom/Badge";
import classNames from "classnames";
import { getOfferDetails } from "~/utils/getOfferDetails";

type Props = {
  product: StrapiProduct;
  className?: string;
  hide?: Array<"offer" | "unique">;
};

export const ProductBadges = ({ product, className, hide }: Props) => {
  const { hasCurrentOffer, offerTitle } = getOfferDetails(product);

  const showOffer = hasCurrentOffer && !hide?.includes("offer");
  const showUnique = product.attributes.isUnique && !hide?.includes("unique");

  return (
    <div className={classNames("flex flex-col items-end gap-1", className)}>
      {showOffer && (
        <Badge color="yellow" className="line-clamp-1 break-all">
          {offerTitle}
        </Badge>
      )}
      {showUnique && <Badge color="green">Unika</Badge>}
    </div>
  );
};
