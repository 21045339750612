import Link from "next/link";
import Icon from "~/assets/icons/Icon";
import Button from "~/components/Button";
import { getFullPath } from "~/utils/getFullPath";
import { useCopyToClipboard } from "usehooks-ts";
import classNames from "classnames";
import { useState } from "react";

type LinkWithCopyProps = {
  pathname: string;
  className?: string;
};
export const LinkWithCopy = ({ pathname, className }: LinkWithCopyProps) => {
  const fullLink = getFullPath(pathname);
  const [, copy] = useCopyToClipboard();
  const [copied, setCopied] = useState(false);

  const onCopy = (text: string) => {
    setCopied(true);
    copy(text);

    setTimeout(() => {
      setCopied(false);
    }, 500);
  };

  return (
    <div className={classNames("flex", className)}>
      <Link className="underline text-primary" href={pathname}>
        {fullLink}
      </Link>
      <Button
        onClick={() => onCopy(fullLink)}
        className={classNames(
          "text-secondary-500 px-2 transition-all hover:text-secondary-600",
          copied && "text-success"
        )}
        title="Kopier link"
      >
        <Icon name="copy" />
      </Button>
    </div>
  );
};
