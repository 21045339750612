import { StrapiProduct } from "~/shared-types";

export const inactiveOfferObject = {
  hasCurrentOffer: false,
  offerTitle: null,
  offerPrice: null,
  discount: null,
  daysTillOfferEnds: null,
};

export type OfferDetails = {
  hasCurrentOffer: boolean;
  offerTitle: string | null;
  offerPrice: number | null;
  discount: number | null;
  daysTillOfferEnds: number | null;
};

export const getOfferDetails = (product: StrapiProduct): OfferDetails => {
  const {
    hasOffer,
    offerStartDate,
    offerEndDate,
    offerPrice,
    offerTitle,
    price,
  } = product.attributes;

  if (
    !hasOffer ||
    !offerStartDate ||
    !offerEndDate ||
    !offerPrice ||
    !offerTitle
  )
    return inactiveOfferObject;

  const today = new Date();
  const offerStart = new Date(offerStartDate);
  const offerEnd = new Date(offerEndDate);

  const isOfferActive = today >= offerStart && today <= offerEnd;

  if (!isOfferActive) return inactiveOfferObject;

  const daysTillOfferEnds = Math.floor(
    (offerEnd.getTime() - today.getTime()) / (1000 * 3600 * 24)
  );

  const discount = price - offerPrice;

  return {
    hasCurrentOffer: true,
    offerTitle,
    offerPrice,
    discount,
    daysTillOfferEnds,
  };
};
