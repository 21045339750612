import { RawCreateParams, z } from "zod";

type TimeStamps = {
  createdAt: string;
  updatedAt: string;
  publishedAt?: string | null;
};
const zTimeStamps = z.object({
  createdAt: z.string(),
  updatedAt: z.string(),
  publishedAt: z.string().nullish(),
});

const zStrapiCollectionType = z.object({
  id: z.number(),
  attributes: zTimeStamps,
});

type StrapiCollectionType = {
  id: number;
  attributes: TimeStamps;
};

type StrapiSingleType = {
  id: number;
  attributes: TimeStamps;
};

const zStrapiEntity = z
  .object({
    id: z.number(),
  })
  .merge(zTimeStamps);

type StrapiEntity = TimeStamps & {
  id: number;
};

export type StrapiResponse<T> = {
  data: T;
  meta: {
    pagination?: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
};

const zStrapiImageMimeType = z.enum([
  "image/jpeg",
  "image/png",
  "image/svg+xml",
  "image/gif",
  "image/webp",
  "image/tiff",
  "image/ico",
]);

const zStrapiImageFormat = z.object({
  ext: z.string(),
  hash: z.string(),
  mime: zStrapiImageMimeType,
  name: z.string(),
  path: z.string().nullable(),
  size: z.number(),
  url: z.string(),
  width: z.number(),
  height: z.number(),
});

const zStrapiMediaBaseAttributes = z.object({
  name: z.string(),
  alternativeText: z.string().nullable(),
  caption: z.string().nullable(),
  hash: z.string(),
  ext: z.string(),
  mime: z.string(),
  size: z.number(),
  url: z.string(),
  previewUrl: z.string().nullable(),
  provider: z.string(),
});

const zStrapiImageAttributes = z.object({
  ...zStrapiMediaBaseAttributes.shape,
  width: z.number(),
  height: z.number(),
  formats: z
    .object({
      large: zStrapiImageFormat.nullish(),
      small: zStrapiImageFormat.nullish(),
      medium: zStrapiImageFormat.nullish(),
      thumbnail: zStrapiImageFormat.nullish(),
    })
    .nullish(),
  mime: zStrapiImageMimeType,
});

const zStrapiImageEntity = z.object({
  ...zStrapiEntity.shape,
  ...zStrapiImageAttributes.shape,
});

export const getZStrapiImage = (params?: RawCreateParams) =>
  z.object(
    {
      ...zStrapiCollectionType.shape,
      attributes: z.object({
        ...zStrapiCollectionType.shape.attributes.shape,
        ...zStrapiImageAttributes.shape,
      }),
    },
    params
  );

export type StrapiImageFormat = z.infer<typeof zStrapiImageFormat>;

export type StrapiMediaBaseAttributes = z.infer<
  typeof zStrapiMediaBaseAttributes
>;

export type StrapiImageEntity = z.infer<typeof zStrapiImageEntity>;

export type StrapiImage = z.infer<ReturnType<typeof getZStrapiImage>>;

const zStrapiVideoAttributes = z.object({
  ...zStrapiMediaBaseAttributes.shape,
  width: z.null(),
  height: z.null(),
  formats: z.null().optional(),
  mime: z.enum([
    "video/mp4",
    "video/ogg",
    "video/webm",
    "video/avi",
    "video/mpeg",
    "video/quicktime",
    "video/flv",
    "video/wmv",
  ]),
});

export type StrapiVideoAttributes = z.infer<typeof zStrapiVideoAttributes>;

const zStrapiVideoEntity = z.object({
  ...zStrapiEntity.shape,
  ...zStrapiVideoAttributes.shape,
});

export type StrapiVideoEntity = z.infer<typeof zStrapiVideoEntity>;

export const zStrapiVideo = z.object({
  ...zStrapiCollectionType.shape,
  attributes: z.object({
    ...zStrapiCollectionType.shape.attributes.shape,
    ...zStrapiVideoAttributes.shape,
  }),
});

export type StrapiVideo = z.infer<typeof zStrapiVideo>;

const zStrapiMedia = z.union([getZStrapiImage(), zStrapiVideo]);
export type StrapiMedia = z.infer<typeof zStrapiMedia>;

const zStrapiMediaEntity = z.union([zStrapiImageEntity, zStrapiVideoEntity]);
export type StrapiMediaEntity = z.infer<typeof zStrapiMediaEntity>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyRecord = Record<string, any>;

type StrapiUserAttributes<TStore = AnyRecord> = {
  id: number;
  username: string;
  email: string;
  provider: "local";
  confirmed: boolean;
  blocked: boolean;
  store: TStore;
};

export type StrapiUserEntity<TStore = AnyRecord> = StrapiEntity &
  StrapiUserAttributes<TStore>;

export type StrapiUser<TStore = AnyRecord> = StrapiCollectionType & {
  attributes: StrapiUserAttributes<TStore>;
};

type StrapiMainCategoryAttributes = {
  name: string;
};

export type StrapiMainCategoryEntity = StrapiEntity &
  StrapiMainCategoryAttributes & {
    icon?: StrapiImageEntity;
    image: StrapiImageEntity;
    stores?: StrapiStoreEntity | null;
  };

export type StrapiMainCategory = StrapiCollectionType & {
  attributes: StrapiMainCategoryAttributes & {
    icon?: { data: StrapiImage };
    image: { data: StrapiImage };
    stores?: { data: StrapiStore[] | null };
  };
};

export type StrapiStoreDeliveryType = "none" | "shipmondo" | "selfManaged";
export type StrapiEncryptedField = {
  iv: string;
  tag: string;
  ciphertext: string;
};

export const getZAddress = (params?: RawCreateParams) =>
  z.object(
    {
      line1: z.string(),
      line2: z.string().optional(),
      city: z.string(),
      postCode: z.string(),
    },
    params
  );

export type Address = z.infer<ReturnType<typeof getZAddress>>;

export const zOtherStore = z.object({
  name: z.string(),
  address: z.string(),
  createdAt: z.number(),
});

type OtherStore = z.infer<typeof zOtherStore>;

export type StrapiStoreAttributes = {
  name: string;
  introduction: string;
  description: string;
  acceptsPickUp?: boolean;
  monday?: string;
  tuesday?: string;
  wednesday?: string;
  thursday?: string;
  friday?: string;
  saturday?: string;
  sunday?: string;
  openingHoursDescription: string;

  // Delivery
  deliveryType: StrapiStoreDeliveryType;
  offersFreeDelivery: boolean;
  deliveryPrice: number;
  shipmondoUsername: StrapiEncryptedField | null;
  shipmondoPassword: StrapiEncryptedField | null;

  publicPhone: string;
  publicEmail: string;
  facebookUrl?: string;
  instagramUrl?: string;
  websiteUrl?: string;

  orderEmail: string;
  adminName?: string;
  adminEmail?: string;
  openingHours?: OpeningHours;
  showAddressOnMap?: boolean;
  sameAddress?: boolean;
  freeDeliveryPurchasePrice: number;
  registrationToken?: string;
  location: { lat: number; lng: number } | null;

  // Store Owner
  cvr: string;
  ownerFirstName: string;
  ownerLastName: string;
  ownerPhone: string;
  ownerEmail: string;
  ownerBirthday: string;

  // Store Bank Account
  bankAccountFirstName: string;
  bankAccountLastName: string;
  bankAccountIban: string;
  bankAccountSwiftBic: string;
  stripeAccountId: string;

  // Addresses
  companyAddress: Address;
  shownAddress: Address | null;

  otherStores: OtherStore[] | null;

  hasNotifiedSuperAdmin: boolean;
};

export const zOpenHoursContent = z.object({
  id: z.number(),
  from: z.string(),
  to: z.string(),
});

export type OpenHoursContent = z.infer<typeof zOpenHoursContent>;

export const zOpeningHourObject = z.object({
  type: z.enum(["open", "openByAgreement", "closed"]),
  hours: z.array(zOpenHoursContent).optional(),
});

export type OpeningHourObject = z.infer<typeof zOpeningHourObject>;

export const zOpeningHours = z.object({
  monday: zOpeningHourObject.optional(),
  tuesday: zOpeningHourObject.optional(),
  wednesday: zOpeningHourObject.optional(),
  thursday: zOpeningHourObject.optional(),
  friday: zOpeningHourObject.optional(),
  saturday: zOpeningHourObject.optional(),
  sunday: zOpeningHourObject.optional(),
});

export type OpeningHours = z.infer<typeof zOpeningHours>;

export type StrapiStoreEntity = StrapiEntity &
  StrapiStoreAttributes & {
    coverVideo?: StrapiVideoEntity | null;
    featuredMedia: StrapiImageEntity;
    storeImage: StrapiImageEntity;
    products?: StrapiProductEntity[] | null;
    mainCategories?: StrapiMainCategoryEntity[] | null;
    orders?: StrapiOrderEntity[] | null;
  };

export type StrapiStore = StrapiCollectionType & {
  attributes: StrapiStoreAttributes & {
    coverVideo?: { data: StrapiVideo | null };
    featuredMedia: { data: StrapiImage };
    storeImage: { data: StrapiImage };
    products?: { data: StrapiProduct[] | null };
    mainCategories?: { data: StrapiMainCategory[] | null };
    orders?: { data: StrapiOrder[] | null };
  };
};

export enum ProductDeclaration {
  ProducedInDenmark = "produced-in-denmark",
  DanishIngredients = "danish-ingredients",
  DanishDesign = "danish-design",
  DanishMaterials = "danish-materials",
}

export type FeaturedImageOrientation = "landscape" | "portrait" | "square";

export type StrapiProductAttributes = {
  name: string;
  price: number;
  deliveryPrice: number;
  description: string;
  danishDesign: boolean;
  danishProduction: boolean;
  danishMaterials: boolean;
  canOnlyBePickedUp: boolean;
  coveredByStoreDeliveryPrice: boolean;
  stockStatus: "notInStock" | "inStock" | "unique";
  isUnique: boolean;
  isFeatured: boolean;
  isValid: boolean;
  hasOffer?: boolean;
  offerTitle?: string;
  offerPrice?: number;
  offerStartDate?: string;
  offerEndDate?: string;
  showWhenNotInStock?: boolean;
  showContactAboutStock?: boolean;
  featuredImageOrientation?: FeaturedImageOrientation;

  weightInGrams?: number;
  followsShipmondoGuidelines?: boolean;

  order?: number;
  isBlocked?: boolean;
};

export type StrapiProductEntity = StrapiEntity &
  StrapiProductAttributes & {
    images: StrapiImageEntity[];
    mainCategory: StrapiMainCategoryEntity;
    subCategories: StrapiSubCategoryEntity[];
    store: StrapiStoreEntity;
    productTags: StrapiProductTagEntity[] | null;
  };

export type StrapiProduct = StrapiCollectionType & {
  attributes: StrapiProductAttributes & {
    images: { data: StrapiImage[] };
    mainCategory: { data: StrapiMainCategory };
    subCategories: { data: StrapiSubCategory[] };
    store: { data: StrapiStore | null };
    productTags: { data: StrapiProductTag[] | null };
  };
};

type StrapiSubCategoryAttributes = {
  name: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
};

export type StrapiSubCategoryEntity = StrapiEntity &
  StrapiSubCategoryAttributes & {
    image: StrapiImageEntity;
    mainCategory?: StrapiMainCategoryEntity;
  };

export type StrapiSubCategory = StrapiCollectionType & {
  attributes: StrapiSubCategoryAttributes & {
    image: { data: StrapiImage };
    mainCategory?: { data: StrapiMainCategory };
  };
};

export type StrapiFrontPage = StrapiSingleType & {
  attributes: {
    welcomeTitle: string;
    welcomeSubtitle: string;
    exploreTitle: string;
    featuredProductsTitle: string;
    exploreSubtitle: string;
    featuredProductsSubtitle: string;
    newStoresTitle: string;
    newStoresSubtitle: string;
    exploreMediaBackground: { data: StrapiMedia | null };
  };
};

export type StrapiTermsAndConditions = StrapiSingleType & {
  attributes: {
    content: string;
  };
};

export type StrapiAboutUs = StrapiSingleType & {
  attributes: {
    content: string;
  };
};

export type StrapiCustomerService = StrapiSingleType & {
  attributes: {
    content: string;
  };
};

export type StrapiBecomeSeller = StrapiSingleType & {
  attributes: {
    content: string;
  };
};

export type StrapiOfferLaws = StrapiSingleType & {
  attributes: {
    content: string;
  };
};

type StrapiCartItemAttributes = {
  productName: string;
  productId: number;
  quantity: number;
  price: number;
  discountPrice: number | null;
};

export type StrapiCartItemEntity = StrapiCartItemAttributes & {
  id: number;
  product: StrapiProductEntity;
};

export type StrapiCartItem = StrapiCartItemAttributes & {
  id: number;
  product: { data: StrapiProduct | null };
};

export type StrapiOrderStatus =
  | "initial"
  | "paid"
  | "shipped"
  | "collected"
  | "return"
  | "cancelled";

export type DeliveryType = "shipping" | "pickup" | "freeShipping" | "shipmondo";

type ShipmondoParcel = {
  weight: number;
  description: string;
  quantity: number;
};

export type StrapiOrderAttributes = {
  hasShipped: boolean;
  paymentIntentId?: string | null;
  // Previous values: paid=confirmed, shipped=captured
  status: StrapiOrderStatus;
  formattedId?: string;
  stripeSessionId?: string;
  customerName?: string;
  customerEmail?: string;
  customerPhone?: string;
  customerAddress?: string;
  customerPostCode?: string;
  customerCity?: string;
  deliveryPrice?: number;
  messageToCustomer?: string;
  totalAmount?: number;
  deliveryType: DeliveryType;
  shipmondoShipmentId?: number;

  shipmondoShipmentData: null | {
    parcels: ShipmondoParcel[];
    productCode: string;
    servicePointId?: string;
  };
};

export type StrapiOrderEntity = StrapiEntity &
  StrapiOrderAttributes & {
    cartItems: StrapiCartItemEntity[];
    store: StrapiStoreEntity | null;
  };

export type StrapiOrder = StrapiCollectionType & {
  attributes: StrapiOrderAttributes & {
    cartItems: StrapiCartItem[];
    store: { data: StrapiStore | null };
  };
};

export type StrapiRelationUpdate = {
  connect: { id: number; position: { end?: boolean; after?: number } }[];
  disconnect: { id: number }[];
};

export type StoreCartItem = {
  product: StrapiProduct;
  quantity: number;
};

export type StoreCart = {
  store: {
    id: number;
    attributes: Pick<
      StrapiStoreAttributes,
      | "deliveryPrice"
      | "deliveryType"
      | "name"
      | "shownAddress"
      | "acceptsPickUp"
      | "freeDeliveryPurchasePrice"
      | "offersFreeDelivery"
    >;
  };
  items: StoreCartItem[];
};

type StrapiRefundAttributes = {
  reason?: string | null;
  amount?: number | null;
  amountLeft?: number | null;
  transferGroup?: string | null;
};

export type StrapiRefundEntity = StrapiEntity &
  StrapiRefundAttributes & {
    refundedItems?: StrapiCartItemEntity[];
    order?: StrapiOrderEntity | null;
    store?: StrapiStoreEntity | null;
  };

export type StrapiRefund = StrapiCollectionType & {
  attributes: StrapiRefundAttributes & {
    refundedItems?: StrapiCartItem[];
    order?: { data: StrapiOrder | null };
    store?: { data: StrapiStore | null };
  };
};

type StrapiProductTagAttributes = {
  title: string;
};

export type StrapiProductTagEntity = StrapiEntity &
  StrapiProductTagAttributes & {
    products: StrapiProductEntity[] | null;
    store: StrapiStoreEntity | null;
  };

export type StrapiProductTag = StrapiCollectionType & {
  attributes: StrapiProductTagAttributes & {
    products: { data: StrapiProduct[] | null };
    store: { data: StrapiStore | null };
  };
};
