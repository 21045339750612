import {
  StrapiImage,
  StrapiImageEntity,
  StrapiImageFormat,
} from "~/shared-types";

const formats = ["thumbnail", "small", "medium", "large", "full"] as const;
type ImageFormat = (typeof formats)[number];

const getNextFormat = (format: ImageFormat): ImageFormat | undefined => {
  const index = formats.indexOf(format);
  if (index === -1) return undefined;

  return formats[index + 1];
};

export const getFormattedImageAttributes = (
  image: StrapiImage | StrapiImageEntity,
  prioritizedFormat: ImageFormat = "full",
  options?: {
    altFallback?: string;
    widthOverride?: number;
    heightOverride?: number;
  }
) => {
  const attributes = getImageAttributes(
    "attributes" in image ? image.attributes : image,
    prioritizedFormat
  );
  return {
    src:
      process.env.NODE_ENV === "development" && attributes.url[0] === "/"
        ? process.env.NEXT_PUBLIC_STRAPI_BASE_URL + attributes.url
        : attributes.url,
    alt: attributes.alternativeText ?? options?.altFallback ?? attributes.name,
    width: options?.widthOverride ?? attributes.width ?? 0,
    height: options?.heightOverride ?? attributes.height ?? 0,
  };
};

export const getImageAttributes = (
  attributes: StrapiImage["attributes"],
  prioritizedFormat: ImageFormat = "full"
):
  | StrapiImage["attributes"]
  | (StrapiImageFormat & { alternativeText: string | null }) => {
  if (!prioritizedFormat || !attributes.formats || prioritizedFormat === "full")
    return attributes;

  const formattedImage = attributes.formats[prioritizedFormat];
  if (formattedImage)
    return {
      ...formattedImage,
      alternativeText: attributes.alternativeText,
    };

  const nextFormat = getNextFormat(prioritizedFormat);
  if (nextFormat) {
    return getImageAttributes(attributes, nextFormat);
  }

  return attributes;
};
