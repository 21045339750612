import React from "react";
import Icon from "~/assets/icons/Icon";
import { getRoute } from "~/utils/getRoute";
import { LinkButton } from "../LinkButton";
import { useFavorites } from "~/atoms/favorites";

export const FavoritesLink = ({
  square,
}: {
  square?: boolean;
}): JSX.Element => {
  const { favorites } = useFavorites();
  const favoritesCount = favorites.length;

  return (
    <LinkButton
      href={getRoute("/favorites")}
      theme="clear"
      square={square}
      className="relative"
      aria-label="Gå til favoritter"
    >
      <Icon name="heart" />

      {favoritesCount > 0 && (
        <div
          className={`absolute bottom-1 w-4 leading-4 bg-secondary-500 text-white font-bold text-center rounded-full ${
            favoritesCount <= 9 ? "text-xs" : "text-2xs"
          } ${square ? "right-2" : "right-4"}`}
        >
          {favoritesCount}
        </div>
      )}
    </LinkButton>
  );
};
