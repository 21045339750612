import Icon from "~/assets/icons/Icon";
import { getRoute } from "~/utils/getRoute";
import { LinkButton } from "../LinkButton";

type ExploreButtonProps = {
  className?: string;
};

export const ExploreButton = ({ className }: ExploreButtonProps) => {
  return (
    <LinkButton href={getRoute("/explore")} className={className}>
      <Icon name="pin" className="w-4 h-4" />
      Udforsk
    </LinkButton>
  );
};
