import QueryString from "qs";
import { QueryFunctionOptions, getQueryObject } from "../shared";
import { strapiClient as defaultStrapiClient } from "~/strapi/api";
import { StrapiOrder, StrapiResponse } from "~/shared-types";

export const storeOrdersOptions = {
  populate: ["cartItems", "cartItems.product", "cartItems.product.images"],
};

export const getStoreOrders = async (options: QueryFunctionOptions = {}) => {
  const { strapiClient = defaultStrapiClient } = options;

  const optionsObject = {
    ...options,
    ...storeOrdersOptions,
  };
  const queryObject = getQueryObject(optionsObject);
  const query = QueryString.stringify(queryObject);

  const response = await strapiClient.get<StrapiResponse<StrapiOrder[]>>(
    `/store-orders?${query}`
  );
  return response.data;
};
