import { Dialog } from "../Dialog";
import { HTMLLevels, Heading } from "~/components/Heading";
import { Text } from "~/components/Text";
import Button from "~/components/Button";
import { showToast } from "~/utils/toast";
import { useState } from "react";
import { strapiClient } from "~/strapi/api";
import { StrapiResponse, StrapiStore } from "~/shared-types";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  storeId: number;
};
export const SubscriptionModal = ({ open, setOpen, storeId }: Props) => {
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showPauseConfirmation, setShowPauseConfirmation] = useState(false);

  const cancelSubscription = async (cancellationType: "pause" | "cancel") => {
    await strapiClient.put<StrapiResponse<StrapiStore>>(
      `/stores/${storeId}/cancel`,
      { cancellationType }
    );

    setShowCancelConfirmation(false);
    setShowPauseConfirmation(false);
    setOpen(false);

    showToast(
      "info",
      "Abonnementet er blevet opdateret",
      cancellationType === "cancel"
        ? "Dit abonnement vil blive annulleret."
        : "Dit abonnement vil blive sat i bero."
    );
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(isOpen) => setOpen(isOpen)}
      trigger={null}
      className="px-12 py-10 z-[1] !h-auto"
      contentClassName="flex flex-col gap-10"
      closeClassName="!top-2 !right-2"
    >
      <Heading level={HTMLLevels.h2} className="text-3xl">
        Mit abonnement
      </Heading>

      <div>
        <Heading level={HTMLLevels.h3} className="mb-2 text-xl">
          Jeg vil gerne opsige mit abonnement
        </Heading>
        <Text>
          Abonnementet opsiges løbende måned plus 30 dage. Når du opsiger dit
          abonnement slettes hele din butik og alle produkterne.
        </Text>
        <Button
          className="mt-4"
          variant="primary"
          size="lg"
          onClick={() => setShowCancelConfirmation(true)}
        >
          Opsig abonnement
        </Button>
      </div>

      <div>
        <Heading level={HTMLLevels.h3} className="mb-2 text-xl">
          Jeg vil gerne sætte mit abonnement i bero
        </Heading>
        <Text>
          Abonnementet sættes i bero, og du betaler kun for løbende måned.
        </Text>
        <Button
          className="mt-4"
          variant="primary"
          size="lg"
          onClick={() => setShowPauseConfirmation(true)}
        >
          Sæt i bero
        </Button>
      </div>

      <Dialog
        trigger={null}
        open={showCancelConfirmation || showPauseConfirmation}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            setShowCancelConfirmation(false);
            setShowPauseConfirmation(false);
          }
        }}
      >
        <div className="px-12 py-10">
          <Text className="mb-2 text-xl">
            Er du sikker på at du vil{" "}
            {showCancelConfirmation
              ? "annullere dit abonnement?"
              : "sætte dit abonnement i bero?"}
          </Text>
          <div className="flex gap-2 mt-4">
            <Button
              variant="secondary"
              size="lg"
              onClick={
                showCancelConfirmation
                  ? () => cancelSubscription("cancel")
                  : () => cancelSubscription("pause")
              }
            >
              Bekræft ændring i abonnement
            </Button>
            <Button
              variant="basic"
              size="lg"
              onClick={() => {
                setShowCancelConfirmation(false);
                setShowPauseConfirmation(false);
              }}
            >
              Annuller
            </Button>
          </div>
        </div>
      </Dialog>
    </Dialog>
  );
};
