import { StrapiStoreEntity } from "~/shared-types";
import { DropdownMenu } from "../DropdownMenu";
import Image from "next/image";
import { getFormattedImageAttributes } from "~/utils/getImageAttributes";
import { useUserQuery } from "~/api/user";
import { useState } from "react";
import { LinksModal } from "./LinksModal";
import { SubscriptionModal } from "./SubscriptionModal";
import Link from "next/link";
import { getRoute } from "~/utils/getRoute";

export const AvatarDropdown = () => {
  const [linksOpen, setLinksOpen] = useState(false);
  const [subscriptionOpen, setSubscriptionOpen] = useState(false);
  const { data: user } = useUserQuery();

  if (!user?.store) return null;

  return (
    <>
      <DropdownMenu
        label="Brugermenu"
        className="!p-1"
        triggerComponent={<StoreAvatar store={user.store} />}
      >
        <DropdownMenu.Item asChild>
          <Link href={getRoute("/backroom/product-tags")}>
            Butikskategorier
          </Link>
        </DropdownMenu.Item>
        <DropdownMenu.Item
          onSelect={() => {
            setLinksOpen(true);
          }}
        >
          Links
        </DropdownMenu.Item>

        <DropdownMenu.Item
          onSelect={() => {
            setSubscriptionOpen(true);
          }}
        >
          Mit abonnement
        </DropdownMenu.Item>
        <DropdownMenu.Item asChild>
          <Link href={getRoute("/backroom/revenue")}>Mine transaktioner</Link>
        </DropdownMenu.Item>
      </DropdownMenu>
      <LinksModal open={linksOpen} setOpen={setLinksOpen} store={user.store} />
      <SubscriptionModal
        open={subscriptionOpen}
        setOpen={setSubscriptionOpen}
        storeId={user.store.id}
      />
    </>
  );
};

type StoreAvatarProps = {
  store: StrapiStoreEntity;
};

function StoreAvatar({ store }: StoreAvatarProps) {
  if (!store.storeImage)
    return (
      <div
        role="img"
        title={store.name}
        className="rounded-full w-11 h-11 lg:order-last bg-secondary-800"
      />
    );

  return (
    <Image
      {...getFormattedImageAttributes(store.storeImage, "thumbnail", {
        altFallback: store.name,
      })}
      className="rounded-full w-11 h-11 lg:order-last flex items-center justify-center object-cover"
      title={store.name}
    />
  );
}
