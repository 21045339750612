import { ComponentProps, ReactNode } from "react";
import {
  Arrow,
  Content,
  Item,
  CheckboxItem,
  Portal,
  Root,
  Separator,
  Trigger,
} from "@radix-ui/react-dropdown-menu";
import {
  CompoundComponentContext,
  useCompoundComponent,
} from "~/hooks/useCompoundComponent";
import classNames from "classnames";
import Icon from "~/assets/icons/Icon";

type DropdownMenuProps = {
  children: ReactNode;
  label: string;
  className?: string;
  triggerComponent?: ReactNode;
};

export function DropdownMenu({
  children,
  label,
  className,
  triggerComponent,
}: DropdownMenuProps) {
  return (
    <Root>
      <Trigger asChild>
        <button
          className={classNames(
            "rounded-full inline-flex items-center justify-center text-secondary-600 outline-none hover:bg-black/5 focus:shadow-[0_0_0_2px] focus:shadow-black p-2 transition-all",
            className
          )}
          title={label}
          aria-label={label}
        >
          {triggerComponent ?? (
            <Icon name="meatball" className="w-6 h-6 pointer-events-none" />
          )}
        </button>
      </Trigger>
      <Portal>
        <Content
          className={classNames(
            "mx-3 bg-white rounded-lg p-2.5 shadow-lg",
            "will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade",
            className
          )}
          sideOffset={-10}
        >
          <CompoundComponentContext.Provider value={"DropdownMenu"}>
            {children}
          </CompoundComponentContext.Provider>
          <Arrow className="fill-white shadow-lg" />
        </Content>
      </Portal>
    </Root>
  );
}

DropdownMenu.Item = function ({
  children,
  ...restProp
}: {
  children: ReactNode;
} & ComponentProps<typeof Item>) {
  useCompoundComponent("DropdownMenu", "Item");
  return (
    <Item
      className={classNames(
        "group rounded-md flex items-center py-1.5 px-2.5 cursor-pointer relative select-none outline-none mb-1 last-of-type:mb-0",
        "text-s text-secondary-600 data-[highlighted]:text-secondary-900 data-[disabled]:text-secondary-300 data-[disabled]:pointer-events-none"
      )}
      {...restProp}
    >
      {children}
    </Item>
  );
};
DropdownMenu.ToggleItem = function ({
  children,
  checked,
  onCheckedChange,
  onSelect,
  ...restProp
}: {
  children: ReactNode;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
} & ComponentProps<typeof CheckboxItem>) {
  useCompoundComponent("DropdownMenu", "ToggleItem");
  return (
    <CheckboxItem
      className={classNames(
        "group rounded-md flex items-center py-1.5 px-2.5 cursor-pointer relative select-none outline-none mb-1 last-of-type:mb-0",
        "text-s text-secondary-600 data-[highlighted]:text-secondary-900 data-[disabled]:text-secondary-300 data-[disabled]:pointer-events-none"
      )}
      checked={checked}
      onCheckedChange={onCheckedChange}
      onSelect={onSelect}
      {...restProp}
    >
      {children}
      <div
        className={classNames(
          "rounded-full ml-2.5 w-5 h-3",
          checked ? "bg-success-500" : "bg-secondary-600"
        )}
      >
        <div
          className={classNames(
            "bg-white rounded-full w-3 h-3 border-2 transition-all",
            checked ? "ml-2 border-success-500" : "ml-0 border-secondary-600"
          )}
        />
      </div>
    </CheckboxItem>
  );
};
DropdownMenu.Separator = function () {
  useCompoundComponent("DropdownMenu", "Separator");
  return <Separator className="h-px bg-secondary-100 m-1.5" />;
};
