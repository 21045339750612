import { useQuery } from "@tanstack/react-query";
import { StrapiStoreEntity, StrapiUserEntity } from "~/shared-types";
import { QueryFunctionOptions } from "./shared";
import { strapiClient as defaultStrapiClient } from "~/strapi/api";

export const USER_QUERY_KEY = "user";

export const getUser = async (options: QueryFunctionOptions = {}) => {
  const { strapiClient = defaultStrapiClient } = options;

  const response = await strapiClient.get<StrapiUserEntity<StrapiStoreEntity>>(
    `/users/me?populate[0]=store&populate[1]=store.storeImage`
  );

  return response.data;
};

export const useUserQuery = () => {
  return useQuery({ queryKey: [USER_QUERY_KEY], queryFn: () => getUser() });
};
