import { useInfiniteQuery } from "@tanstack/react-query";
import QueryString from "qs";
import { StrapiOrder, StrapiResponse } from "~/shared-types";
import {
  QueryFunctionOptions,
  getNextPageParam,
  getQueryObject,
} from "./shared";
import { getStoreOrders } from "./storeOrders";

export const ORDERS_QUERY_KEY = "orders";

export const useOrdersQuery = (
  options: QueryFunctionOptions = {},
  initialData?: StrapiResponse<StrapiOrder[]>
) => {
  const queryObject = getQueryObject(options);
  const query = QueryString.stringify(queryObject);

  const orders = useInfiniteQuery({
    queryKey: [ORDERS_QUERY_KEY, query],
    queryFn: async ({ pageParam = 1 }) =>
      getStoreOrders({ ...options, pageParam }),
    getNextPageParam,
    initialPageParam: initialData ? 1 : undefined,
    initialData: {
      pages: [initialData],
      pageParams: [initialData ? 1 : undefined],
    },
  });
  return orders;
};
