import { FeaturedImageOrientation, StrapiImage } from "~/shared-types";
import { getFormattedImageAttributes } from "./getImageAttributes";

export const getAspectRatioClass = (
  image: StrapiImage | null | undefined,
  orientation?: FeaturedImageOrientation
) => {
  if (!image) return "aspect-square";

  if (orientation) {
    if (orientation === "landscape") return "aspect-[4/3]";
    if (orientation === "portrait") return "aspect-[3/4]";
    if (orientation === "square") return "aspect-square";
  }

  const { width, height } = getFormattedImageAttributes(image, "large");
  if (width < height) return "aspect-[3/4]";
  if (width > height) return "aspect-[4/3]";
  return "aspect-square";
};
