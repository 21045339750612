import { StrapiMainCategory, StrapiSubCategory } from "~/shared-types";

export type GroupedCategory = {
  mainCategory: StrapiMainCategory;
  subCategories: StrapiSubCategory[];
  id: number;
};

export const getGroupedCategories = (categories: StrapiSubCategory[]) => {
  if (!categories) return [];

  return categories.reduce((groupedCategories, currentCategory) => {
    const mainCategory = currentCategory?.attributes?.mainCategory?.data;

    if (!mainCategory) return groupedCategories;

    const existingCategory = groupedCategories.find((group) => {
      return group.mainCategory.id === mainCategory.id;
    });

    if (existingCategory) {
      const updatedSubCategories = existingCategory.subCategories
        .concat(currentCategory)
        .sort((a, b) => a.attributes.name.localeCompare(b.attributes.name));

      existingCategory.subCategories = updatedSubCategories;
    } else {
      groupedCategories.push({
        mainCategory,
        subCategories: [currentCategory],
        id: mainCategory.id,
      });

      groupedCategories = groupedCategories.sort((a, b) =>
        a.mainCategory.attributes.name.localeCompare(
          b.mainCategory.attributes.name
        )
      );
    }

    return groupedCategories;
  }, [] as GroupedCategory[]);
};
