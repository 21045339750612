import classNames from "classnames";
import { toast, Toast } from "react-hot-toast";
import Icon from "~/assets/icons/Icon";
import { IconType } from "~/assets/icons/iconPaths";

type BaseToastProps = {
  t: Toast;
  title: string;
  description?: string;
  iconName: IconType;
  className: string;
};

const BaseToast = (props: BaseToastProps) => {
  const { t, title, description, className, iconName } = props;

  return (
    <div
      className={classNames(
        "toast",
        t.visible ? "toast--animate-enter" : "toast--animate-leave",
        className
      )}
    >
      <Icon className="toast__icon" name={iconName} />
      <div className="toast__content">
        <p className="toast__content__title">{title}</p>
        {description ? (
          <p className="toast__content__description">{description}</p>
        ) : null}
      </div>

      <button
        className="toast__close-button"
        onClick={() => toast.dismiss(t.id)}
      >
        <Icon name="close" />
      </button>
    </div>
  );
};

type StyledToastProps = {
  t: Toast;
  title: string;
  description?: string;
};

const ErrorToast = (props: StyledToastProps) => {
  return <BaseToast {...props} iconName="danger" className="toast--danger" />;
};

const SuccessToast = (props: StyledToastProps) => {
  return <BaseToast {...props} iconName="success" className="toast--success" />;
};

const WarningToast = (props: StyledToastProps) => {
  return <BaseToast {...props} iconName="warning" className="toast--warning" />;
};

const InfoToast = (props: StyledToastProps) => {
  return <BaseToast {...props} iconName="info" className="toast--info" />;
};

export const showToast = (
  type: "success" | "warning" | "danger" | "info",
  title: string,
  description?: string
) => {
  const baseProps = {
    title,
    description,
  };
  if (type === "danger")
    return toast.custom((t) => <ErrorToast {...baseProps} t={t} />);
  if (type === "success")
    return toast.custom((t) => <SuccessToast {...baseProps} t={t} />);
  if (type === "warning")
    return toast.custom((t) => <WarningToast {...baseProps} t={t} />);

  return toast.custom((t) => <InfoToast {...baseProps} t={t} />);
};
