import { Logo } from "~/components/Logo";
import CartButton from "~/components/CartButton";
import { Inter } from "@next/font/google";
import { getRoute } from "~/utils/getRoute";
import Link from "next/link";
import { ExploreButton } from "~/components/ExploreButton";
import { DesktopMenu } from "../DesktopMenu";
import { GroupedCategory } from "~/helpers/getGroupedCategories";
import { useUserQuery } from "~/api/user";
import Icon from "~/assets/icons/Icon";
import { FavoritesLink } from "~/components/FavoritesLink";
import { useSingleStoreAtom } from "~/atoms/singleStore";
import { LinkButton } from "~/components/LinkButton";

const inter = Inter({ subsets: ["latin"] });

function DesktopNavBar({ categories }: { categories: GroupedCategory[] }) {
  const { data: user } = useUserQuery();
  const { isLimited, limitedStoreId } = useSingleStoreAtom();
  const logoHref = isLimited
    ? // We can safely assume that limitedStoreId is not null here, because isLimited is true
      getRoute("/stores/:id", { id: limitedStoreId as number })
    : "/";

  return (
    <header className="relative hidden lg:flex justify-between items-center px-2 md:px-16 lg:px-20 py-8 h-navbar">
      {/*
        h-navbar is used to set the height of the navbar. It is defined in tailwind.config.js as a css variable.
        The css variable is defined in global.scss.
        This height is used in multiple places to control the height of the content.
        Example: Map height on Explore page (navbar + map = 100vh)
      */}
      <div className="flex items-center gap-2">
        <Logo href={logoHref} />
        {user?.id && (
          <LinkButton
            theme="secondary"
            href={getRoute("/backroom/products")}
            className="!gap-1 text-secondary-600 hover:underline text-xs font-bold !p-2"
          >
            <Icon name="arrowLeft" className="w-3 min-w-[0.75rem]" />
            Baglokalet
          </LinkButton>
        )}
      </div>
      <nav className={`${inter.className} text-black`}>
        <div className="flex max-h-10 items-center">
          {!isLimited && (
            <ul className="flex flex-row items-center">
              <li>
                <DesktopMenu categories={categories} />
              </li>

              <li className="px-4 hover:text-primary-600">
                <Link href={getRoute("/stores")}>Butikker</Link>
              </li>
              <li className="px-4 hover:text-primary-600">
                <Link target="_blank" href="https://info.butikdanmark.dk">
                  Om Butik Danmark
                </Link>
              </li>
            </ul>
          )}

          {!isLimited && (
            <>
              <span className="hidden lg:inline border-r border-secondary-500 self-stretch mx-4" />
              <FavoritesLink />
            </>
          )}

          <CartButton />
          {!isLimited && <ExploreButton className="flex ml-4" />}
        </div>
      </nav>
    </header>
  );
}

export default DesktopNavBar;
