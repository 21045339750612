import classNames from "classnames";
import Icon from "~/assets/icons/Icon";
import { IconType } from "~/assets/icons/iconPaths";
import { Logo } from "~/components/Logo";
import { HamburgerMenu } from "../HamburgerMenu";
import { ComponentProps, forwardRef } from "react";
import { AlertDialog } from "../AlertDialog";
import { useRouter } from "next/router";
import { getRoute } from "~/utils/getRoute";
import { MyOrdersLink } from "./MyOrdersLink";
import { AvatarDropdown } from "./AvatarDropdown";
import { HTMLLevels, Heading } from "~/components/Heading";
import Link from "next/link";

export function NavigationBar() {
  const router = useRouter();

  return (
    <div className="flex justify-between items-center bg-secondary-100 py-2 px-8 m-5 lg:m-8 mb-0 rounded-lg">
      <div className="flex gap-4 items-center">
        <Logo size="icon" className="block w-10 max-w-[112px]" />
        <Link
          href={getRoute("/backroom/products")}
          className="text-secondary-700 flex flex-col"
        >
          <Heading className="lg:text-xl font-bold" level={HTMLLevels.h3}>
            Baglokalet
          </Heading>
          <span className="text-sm">Administrer din butik</span>
        </Link>
      </div>
      <HamburgerMenu id="hamburger-menu" label="Menu">
        <div className="flex flex-col h-full fixed lg:static z-20 inset-0 bg-secondary-100">
          <div className="flex lg:hidden justify-between items-center bg-secondary-100 py-2 px-8 m-5 lg:m-8 mb-0 rounded-lg">
            <div className="flex gap-4 items-center">
              <Logo size="icon" className="block w-10 max-w-[112px]" />
              <span className="text-secondary-700 flex flex-col">
                <Heading className="lg:text-xl font-bold" level={HTMLLevels.h3}>
                  Baglokalet
                </Heading>
                <span className="text-sm">Administrer din butik</span>
              </span>
            </div>
            <HamburgerMenu.Close
              className="p-2 hover:bg-black/5 rounded-full transition-all"
              aria-label="Luk menu"
            >
              <Icon name="close" className="text-black w-6 h-6" />
            </HamburgerMenu.Close>
          </div>
          <div className="flex flex-col lg:flex-row items-center justify-between lg:justify-start grow">
            <div className="flex flex-col lg:flex-row gap-1.5 min-[1124px]:gap-3 w-full max-w-[350px] lg:w-auto lg:max-w-full p-5 lg:py-0 lg:px-1.5 min-[1124px]:px-6">
              <HamburgerMenu.Link href="/backroom/products" icon="lamp">
                Mine produkter
              </HamburgerMenu.Link>
              <HamburgerMenu.Link href="/backroom/store" icon="storeAlt">
                Min butik
              </HamburgerMenu.Link>
              <MyOrdersLink />
            </div>
            <div className="flex justify-center gap-3 min-[1124px]:gap-6 bg-secondary-200 lg:bg-transparent w-full lg:w-auto p-7 lg:p-0">
              <div
                className="border-l border-secondary-500"
                role="separator"
                aria-orientation="vertical"
              />
              <AlertDialog
                trigger={<NavBarIconButton label="Log ud" icon="lockOutline" />}
                iconName="warning"
              >
                <h4 className="text-secondary-800 m-0 text-xl font-medium mb-5 text-center">
                  Du er ved at logge ud
                </h4>
                <div className="flex gap-5 mt-10">
                  <AlertDialog.Cancel className="grow">
                    Tilbage
                  </AlertDialog.Cancel>

                  <AlertDialog.Ok
                    className="grow"
                    onClick={() => {
                      return router.replace(getRoute("/backroom/logout"));
                    }}
                  >
                    Log ud
                  </AlertDialog.Ok>
                </div>
              </AlertDialog>
              <AvatarDropdown />
            </div>
          </div>
        </div>
      </HamburgerMenu>
    </div>
  );
}

export const NavBarIconButton = forwardRef<
  HTMLButtonElement,
  {
    label: string;
    icon: IconType;
  } & ComponentProps<"button">
>(({ label, icon, ...restProps }, ref) => {
  return (
    <button
      className={classNames(
        "flex items-center gap-2.5 p-2.5 rounded-lg whitespace-nowrap text-secondary-600 hover:text-secondary-900"
      )}
      aria-label={label}
      {...restProps}
      ref={ref}
    >
      <Icon name={icon} className="w-6 h-6 shrink-0" />
    </button>
  );
});
