import classNames from "classnames";
import Link from "next/link";
import { ReturnedRoute } from "~/utils/getRoute";

export type LinkButtonTheme =
  | "primary"
  | "secondary"
  | "ternary"
  | "white"
  | "link"
  | "clear";

type LinkButtonProps = {
  href?: ReturnedRoute | string;
  theme?: LinkButtonTheme;
  round?: boolean;
  square?: boolean;
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  target?: string;
};

export const LinkButton = ({
  href = undefined,
  theme = "primary",
  round = false,
  square = false,
  children,
  className,
  disabled,
  onClick,
  target,
  ...rest
}: LinkButtonProps) => {
  const btnClass = classNames(
    "flex gap-3 items-center flex-nowrap",
    "transition-colors duration-200 ease-in-out",
    "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-info-500 focus-visible:ring-offset-2",
    "disabled:bg-neutral-100 disabled:text-neutral-400",
    theme === "primary" &&
      "bg-primary text-white hover:bg-primary-600 active:bg-primary-700",
    theme === "secondary" && "bg-secondary-100 text-secondary-600",
    theme === "ternary" && "bg-primary-300 text-white",
    theme === "white" && "bg-white text-secondary-600 hover:text-secondary-500",
    theme === "clear" && "text-secondary-600 hover:text-secondary-500",
    theme === "link" ? "bg-transparent" : "justify-center",
    round ? "rounded-full" : "rounded-lg",
    square ? "p-3" : theme === "link" ? null : "px-5 py-3",
    className
  );
  return href === undefined ? (
    <button
      className={btnClass}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  ) : (
    <Link
      target={target}
      href={href}
      className={btnClass}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Link>
  );
};
