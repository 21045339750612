import "~/styles/globals.scss";
import "~/styles/swiper-overrides.scss";
import "~/styles/toast.scss";

import type { AppProps } from "next/app";
import { Layout } from "~/layouts/layout";
import { CartProvider } from "~/context/cart";
import Head from "next/head";
import { BannerScript } from "~/utils/cookieBot";
import { Provider } from "jotai";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { StoreValidation } from "./StoreValidation";
import { createPortal } from "react-dom";
import { useIsClient } from "usehooks-ts";
import { GoogleAnalytics } from "@next/third-parties/google";

const queryClient = new QueryClient();

export default function App({ Component, pageProps }: AppProps) {
  const isClient = useIsClient();

  return (
    <Provider>
      <QueryClientProvider client={queryClient}>
        <StoreValidation>
          <CartProvider>
            <Head>
              <title>Butik Danmark</title>
              <meta
                name="description"
                content="Alt det gode danske håndværk - samlet ét sted"
              />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              {process.env.NODE_ENV === "production" ? (
                <link rel="icon" href="/favicon.png" />
              ) : (
                <link rel="icon" href="/faviconDev.png" />
              )}
            </Head>
            <BannerScript />
            {isClient &&
              createPortal(
                <Toaster position="bottom-right" reverseOrder={false} />,
                document.body
              )}
            <Layout>
              <Component {...pageProps} />
              {process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID ? (
                <GoogleAnalytics
                  gaId={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}
                />
              ) : null}
            </Layout>
          </CartProvider>
        </StoreValidation>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  );
}
