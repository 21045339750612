import classNames from "classnames";

type BadgeProps = {
  children: React.ReactNode;
  color?: keyof typeof colorMap;
  className?: string;
};
const colorMap = {
  lightgray: "bg-secondary-200 text-black",
  gray: "bg-secondary-600 text-white",
  green: "bg-success-500 text-white",
  pink: "bg-primary-300 text-white",
  red: "bg-primary-500 text-white",
  yellow: "bg-warning-400 text-black",
};

export function Badge({ children, color = "gray", className }: BadgeProps) {
  return (
    <div
      className={classNames(
        "text-xs px-1.5 py-0.5 rounded-lg max-w-max",
        className,
        colorMap[color]
      )}
    >
      {children}
    </div>
  );
}
