import { DefinedUseInfiniteQueryResult } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export const useWithFetchAll = <T extends DefinedUseInfiniteQueryResult>(
  query: T
) => {
  const [shouldFetchAll, setShouldFetchAll] = useState(false);

  const { hasNextPage, isFetching, fetchNextPage } = query;

  // Effect: Fetch all pages immediately
  useEffect(() => {
    if (shouldFetchAll && hasNextPage && !isFetching) fetchNextPage();
  }, [hasNextPage, isFetching]);

  return { ...query, fetchAllPages: () => setShouldFetchAll(true) };
};
