import Icon from "~/assets/icons/Icon";
import Image from "next/image";
import { getFormattedImageAttributes } from "~/utils/getImageAttributes";
import Link from "next/link";
import { getRoute } from "~/utils/getRoute";
import {
  StrapiMainCategory,
  StrapiProduct,
  StrapiStore,
  StrapiSubCategory,
} from "~/shared-types";
import { Text } from "~/components/Text";
import { useOnClickOutside } from "usehooks-ts";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { GroupedCategory } from "~/helpers/getGroupedCategories";
import { ProductBadges } from "~/components/ProductBadges";
import { getAspectRatioClass } from "~/utils/getAspectRatioClass";
import { useProductsQuery } from "~/api/products";
import { useSingleStoreAtom } from "~/atoms/singleStore";

type DesktopMenuProps = {
  categories: GroupedCategory[];
};

export function DesktopMenu({ categories }: DesktopMenuProps) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { data } = useProductsQuery({
    populate: ["store", "store.storeImage"],
  });

  const { isLimited } = useSingleStoreAtom();

  if (isLimited) return null;

  const products = data?.pages
    .flatMap((page) => page?.data)
    .filter(Boolean) as StrapiProduct[];

  const ref = useRef(null);
  const menuBtnRef = useRef<HTMLButtonElement>(null);

  // Do not use closeMenu() since clicking outside the menu
  // should not set focus back to the menu toggle button.
  useOnClickOutside(ref, () => setIsOpen(false));

  const randomProduct = products[Math.floor(Math.random() * products.length)];
  const randomStore = randomProduct?.attributes.store.data;

  function clickHandler(e: React.MouseEvent<HTMLDivElement>) {
    const target = e.target as HTMLElement;
    target.closest("[data-close-on-click]") && closeMenu();
  }

  function toggleMenu() {
    isOpen ? closeMenu() : openMenu();
  }
  function openMenu() {
    setIsOpen(true);
  }
  function closeMenu() {
    setIsOpen(false);
    menuBtnRef.current?.focus();
  }

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        closeMenu();
      }
    };

    if (!isOpen) {
      window.removeEventListener("keyup", handleKeyDown);
      return;
    }

    window.addEventListener("keyup", handleKeyDown);
    return () => window.removeEventListener("keyup", handleKeyDown);
  }, [isOpen]);

  return (
    <div ref={ref}>
      <button
        ref={menuBtnRef}
        className={classNames(
          "px-4 hover:text-primary-600",
          isOpen && "text-primary"
        )}
        onClick={toggleMenu}
        aria-expanded={isOpen}
        aria-controls="desktop-menu"
      >
        Produkter
      </button>
      <div className="absolute z-10 left-0 right-0 top-full pointer-events-none overflow-y-hidden h-[calc(100vh-114px)]">
        <div
          id="desktop-menu"
          role="menu"
          aria-label="Produktoversigt"
          className={classNames(
            "transition-all duration-500 ease-out bg-secondary-50 h-full flex flex-col justify-between pointer-events-auto",
            isOpen
              ? "translate-y-0 opacity-1"
              : "pointer-events-none -translate-y-full opacity-0"
          )}
        >
          <div
            className="w-full grid grid-cols-[1fr_3fr] max-w-[1920px] mx-auto gap-20 items-start py-10 px-20 mb-3"
            onClick={clickHandler}
          >
            {/* TODO: Create a proper test that does not involve inserting a "dummy" link in production code! */}
            {process.env.NODE_ENV === "test" && (
              <a
                href="/"
                onClick={(e) => e.preventDefault()}
                data-testid="desktop-menu-link"
                data-close-on-click
              />
            )}
            <div className="relative">
              <DesktopMenuProduct
                randomProduct={randomProduct}
                onLoadingCompleted={() => setImageLoaded(true)}
              />
              {randomStore && imageLoaded && (
                <div className="absolute left-0 right-0 bottom-0 p-5">
                  <DesktopMenuStore
                    store={randomStore}
                    tabIndex={isOpen ? 0 : -1}
                  />
                </div>
              )}
            </div>
            <div className="grid grid-cols-4 gap-x-5 gap-y-9">
              {categories.map((group) => (
                <DesktopMenuCategory
                  key={group.id}
                  category={group.mainCategory}
                  subCategories={group.subCategories}
                  tabIndex={isOpen ? 0 : -1}
                />
              ))}
            </div>
          </div>
          <button
            className="flex justify-center items-center bg-secondary-900 hover:bg-secondary-800 text-white p-2"
            onClick={closeMenu}
            tabIndex={isOpen ? 0 : -1}
          >
            <Icon className="mr-2 w-5 h-5" name="closeCircle" />
            Luk menuen
          </button>
        </div>
      </div>
    </div>
  );
}

export const DesktopMenuProduct = ({
  randomProduct,
  onLoadingCompleted,
}: {
  randomProduct?: StrapiProduct | null;
  onLoadingCompleted: () => void;
}) => {
  if (!randomProduct) return null;

  const image = randomProduct.attributes.images.data[0];
  const aspectRatioClass = getAspectRatioClass(image);

  return (
    <Link
      href={getRoute("/products/:productId", {
        productId: randomProduct.id,
      })}
      data-close-on-click
    >
      <Image
        {...getFormattedImageAttributes(
          randomProduct.attributes.images.data[0],
          "large",
          { altFallback: randomProduct.attributes.name }
        )}
        className={classNames(
          "rounded-xl object-cover w-full",
          aspectRatioClass
        )}
        onLoadingComplete={onLoadingCompleted}
      />
      <ProductBadges
        className="absolute top-2 right-2"
        product={randomProduct}
      />
    </Link>
  );
};

export function DesktopMenuCategory({
  category,
  subCategories,
  tabIndex = 0,
}: {
  category: StrapiMainCategory;
  subCategories: StrapiSubCategory[];
  tabIndex?: number;
}) {
  const { name } = category.attributes;

  if (subCategories?.length === 0) return null;

  return (
    <div className="flex flex-col items-start">
      <Link
        href={getRoute("/categories/:categoryId/:categoryName", {
          categoryId: category.id,
          categoryName: name,
        })}
        className="text-2xl mb-2 hover:text-primary-600"
        data-close-on-click
        tabIndex={tabIndex}
      >
        {name}
      </Link>
      {subCategories.map((subCategory) => (
        <DesktopMenuSubCategory
          key={subCategory.id}
          mainCategory={category}
          subCategory={subCategory}
          tabIndex={tabIndex}
        />
      ))}
    </div>
  );
}

export function DesktopMenuSubCategory({
  mainCategory,
  subCategory,
  tabIndex = 0,
}: {
  mainCategory: StrapiMainCategory;
  subCategory: StrapiSubCategory;
  tabIndex?: number;
}) {
  const subCategoryName = subCategory.attributes.name;
  return (
    <Link
      href={getRoute(
        "/categories/:categoryId/:categoryName/:subCategoryId/:subCategoryName",
        {
          categoryId: mainCategory.id,
          categoryName: mainCategory.attributes.name,
          subCategoryId: subCategory.id,
          subCategoryName,
        }
      )}
      className="leading-4 py-[3px] text-sm hover:text-primary-600"
      data-close-on-click
      tabIndex={tabIndex}
    >
      {subCategoryName}
    </Link>
  );
}

export function DesktopMenuStore({
  store,
  tabIndex = 0,
}: {
  store: StrapiStore;
  tabIndex?: number;
}) {
  return (
    <Link
      href={getRoute("/stores/:id", { id: store.id })}
      className="max-w-max flex items-center gap-2 rounded-md p-1 pr-3.5 bg-white-500"
      data-close-on-click
      tabIndex={tabIndex}
      aria-label={`Butik ${store.attributes.name} i ${store.attributes.companyAddress?.city}`}
    >
      <Image
        {...getFormattedImageAttributes(
          store.attributes.storeImage.data,
          "thumbnail"
        )}
        alt={"Butik Skovbrynet"}
        className="rounded-full h-8 w-8"
      />
      <div className="flex flex-wrap gap-x-2 my-1">
        <Text className="text-xs">{store.attributes.name}</Text>
        <Text className="text-secondary-600 text-xs flex items-center">
          <Icon name="pinOutline" className="mr-1 w-3.5 h-3.5" />
          {store.attributes.companyAddress?.city}
        </Text>
      </div>
    </Link>
  );
}
