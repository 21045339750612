import { LinkButton } from "../../LinkButton";
import Icon from "~/assets/icons/Icon";
import { ReturnedRoute, getRoute } from "~/utils/getRoute";
import Image from "next/image";
import { getFormattedImageAttributes } from "~/utils/getImageAttributes";
import classNames from "classnames";
import { IconType } from "~/assets/icons/iconPaths";
import { GroupedCategory } from "~/helpers/getGroupedCategories";
import { useUserQuery } from "~/api/user";

export type MenuLinkProps = {
  href: ReturnedRoute | string;
  name: IconType;
  text: string;
  onClick?: () => void;
  target?: string;
};

export type MobileMenuProps = {
  categories: GroupedCategory[];
  isOpen: boolean;
  closeMenu: () => void;
};

const links: Array<MenuLinkProps & { id: number }> = [
  { id: 1, href: getRoute("/stores"), name: "home", text: "Butikker" },
  { id: 2, href: getRoute("/explore"), name: "pinOutline", text: "Udforsk" },
  {
    id: 3,
    href: "https://info.butikdanmark.dk",
    name: "storeAlt",
    text: "Om Butik Danmark",
    target: "_blank",
  },
  {
    id: 4,
    href: "https://info.butikdanmark.dk/bliv-medlem",
    name: "heartHand",
    text: "Åbn din egen butik",
    target: "_blank",
  },
  {
    id: 5,
    href: getRoute("/customer-service"),
    name: "messageSmile",
    text: "Kundeservice",
  },
];

export function MobileMenu({ categories, isOpen, closeMenu }: MobileMenuProps) {
  const { data: user } = useUserQuery();

  return (
    <div className="fixed inset-0 z-10 overflow-x-hidden pointer-events-none">
      <div
        className={classNames(
          "transition-transform duration-500 h-full flex flex-col overflow-y-auto bg-secondary-50 pointer-events-auto",
          isOpen ? "translate-x-0" : "translate-x-full"
        )}
        role="menu"
        aria-label="Produktliste"
      >
        <LinkButton
          className="fixed z-20 top-8 right-2"
          theme="clear"
          onClick={closeMenu}
          square
          aria-label="Luk menu"
        >
          <Icon name="closeCircle" />
        </LinkButton>
        <ul className="pt-28 pb-5">
          {user?.id && (
            <li>
              <LinkButton
                theme="secondary"
                href={getRoute("/backroom/products")}
                className="!gap-1 text-secondary-600 hover:underline text-base font-bold"
              >
                <Icon name="arrowLeft" className="w-4 min-w-[1rem]" />
                Baglokalet
              </LinkButton>
            </li>
          )}
          {categories.map(({ id, mainCategory }) => (
            <li key={id}>
              <LinkButton
                theme="link"
                className="px-5 py-2.5 text-2xl min-h-[56px] hover:bg-neutral-50"
                href={getRoute("/categories/:categoryId/:categoryName", {
                  categoryId: mainCategory.id,
                  categoryName: mainCategory.attributes.name,
                })}
                onClick={closeMenu}
              >
                {mainCategory.attributes.icon?.data ? (
                  <>
                    <Image
                      {...getFormattedImageAttributes(
                        mainCategory.attributes.icon.data,
                        "large",
                        {
                          altFallback: "",
                          widthOverride: 36,
                          heightOverride: 36,
                        }
                      )}
                    />
                    {mainCategory.attributes.name}
                  </>
                ) : (
                  <span className="ml-12">{mainCategory.attributes.name}</span>
                )}
              </LinkButton>
            </li>
          ))}
        </ul>
        <ul className="bg-primary flex-grow py-5">
          {links.map(({ id, href, name, text, target }) => (
            <MenuLink
              key={id}
              href={href}
              name={name}
              text={text}
              onClick={closeMenu}
              target={target}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

export function MenuLink({ href, name, text, onClick }: MenuLinkProps) {
  return (
    <li>
      <LinkButton
        theme="link"
        className="px-5 py-3 text-base text-white"
        href={href}
        onClick={onClick}
      >
        <Icon name={name} className="w-5 h-5" />
        {text}
      </LinkButton>
    </li>
  );
}
