export const convertBooleanToRadioString = (bool?: boolean): "yes" | "no" => {
  if (bool === true) {
    return "yes";
  } else {
    return "no";
  }
};

export const convertRadioStringToBoolean = (
  radioString?: "yes" | "no" | null
): boolean => {
  if (radioString === "yes") {
    return true;
  } else {
    return false;
  }
};
