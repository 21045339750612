import { Cart, CompressedCart } from "~/types";

export default function compressCart(cart: Cart) {
  const compressed: CompressedCart = { pids: [], qs: [] };

  for (const { productId, quantity } of cart) {
    compressed.pids.push(productId);
    compressed.qs.push(quantity);
  }

  return compressed;
}
