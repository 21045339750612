import classNames from "classnames";
import React from "react";

const QuestionmarkIcon = ({ className }: { className?: string }) => (
  <span
    className={classNames(
      "flex justify-center items-center w-[16px] h-[16px] text-2xs font-semibold",
      className
    )}
  >
    ?
  </span>
);

export default QuestionmarkIcon;
