import React, { useContext } from "react";
import Icon from "~/assets/icons/Icon";
import { CartContext } from "~/context/cart";
import { getRoute } from "~/utils/getRoute";
import { LinkButton } from "../LinkButton";

export default function CartButton({
  square,
}: {
  square?: boolean;
}): JSX.Element {
  const { cart, compressedCart } = useContext(CartContext);
  const itemsCount = cart.reduce((sum, item) => sum + item.quantity, 0);

  return (
    <LinkButton
      href={getRoute("/cart")}
      theme="clear"
      square={square}
      className="relative"
      aria-label="Gå til din kurv"
    >
      <Icon name="cart" />

      {itemsCount > 0 && (
        <div
          data-cart={JSON.stringify(compressedCart)}
          className={`absolute bottom-1 w-4 leading-4 bg-primary-500 text-white font-bold text-center rounded-full ${
            itemsCount <= 9 ? "text-xs" : "text-2xs"
          } ${square ? "right-2" : "right-4"}`}
        >
          {itemsCount}
        </div>
      )}
    </LinkButton>
  );
}
