import { Dialog } from "../Dialog";
import { HTMLLevels, Heading } from "~/components/Heading";
import { Text } from "~/components/Text";
import { LinkWithCopy } from "~/components/LinkWithCopy";
import { StrapiStoreEntity } from "~/shared-types";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  store: StrapiStoreEntity;
};
export const LinksModal = ({ open, setOpen, store }: Props) => {
  const convertedName = encodeURIComponent(store.name.replace(/ /g, "-"));

  return (
    <Dialog
      open={open}
      onOpenChange={(isOpen) => setOpen(isOpen)}
      trigger={null}
      className="px-12 py-10 z-[1]"
      closeClassName="!top-2 !right-2"
    >
      <Heading level={HTMLLevels.h2} className="mb-10 text-3xl">
        Links til min butik
      </Heading>

      <Text>
        Følgende link fører direkte til din butiks forside, men giver også
        adgang til resten af Butik Danmark:
      </Text>
      <LinkWithCopy
        className="mt-1"
        pathname={`/butikker/${store.id}/${convertedName}`}
      />

      <Text className="mt-8">
        Følgende link fører til en låst udgave af din butik, hvor den besøgende
        ikke kan klikke sig videre til andre butikker:
      </Text>
      <LinkWithCopy
        className="mt-1"
        pathname={`/butikker/${store.id}/${convertedName}?ssv=1`}
      />
    </Dialog>
  );
};
