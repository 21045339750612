import { createContext, useContext } from "react";

type CompoundComponentContextValues = null | string;

export const CompoundComponentContext =
  createContext<CompoundComponentContextValues>(null);

export function useCompoundComponent(component: string, subComponent: string) {
  const context = useContext(CompoundComponentContext);

  if (context === null || context !== component) {
    throw new Error(
      `Child components of ${component} cannot be rendered outside the ${component} component!\nFailing compounded component: ${component}.${subComponent}`
    );
  }

  return context;
}
