import { useInfiniteQuery } from "@tanstack/react-query";
import QueryString from "qs";
import { StrapiProduct, StrapiResponse } from "~/shared-types";
import { strapiClient as defaultStrapiClient } from "~/strapi/api";
import {
  QueryFunctionOptions,
  getNextPageParam,
  getQueryObject,
} from "./shared";

export type GetProductsOptions = QueryFunctionOptions & {
  random?: boolean;
};

export const PRODUCTS_QUERY_KEY = "products";

export const getProductQuery = (options: GetProductsOptions = {}) => {
  const queryObject = getQueryObject({
    ...options,
    populate: [
      ...(options.populate ?? []),
      "images",
      "subCategories",
      "subCategories.mainCategory",
      "store",
      "store.storeImage",
      "productTags",
      "mainCategory",
    ],
    filters: {
      ...options.filters,
      // We add a filter to exclude blocked products
      isBlocked: { $ne: true },
    },
  });

  return QueryString.stringify(queryObject);
};

export const getProduct = async (
  productId: number | string,
  options: GetProductsOptions = {}
) => {
  const { strapiClient = defaultStrapiClient } = options;

  const query = getProductQuery(options);
  const { data } = await strapiClient.get<StrapiResponse<StrapiProduct>>(
    `/products/${productId}?${query}`
  );

  return data;
};

export async function getProducts(options: GetProductsOptions = {}) {
  const { strapiClient = defaultStrapiClient } = options;

  const query = getProductQuery(options);
  const { data } = await strapiClient.get<StrapiResponse<StrapiProduct[]>>(
    options.random ? `/products/random?${query}` : `/products?${query}`
  );

  return data;
}

export const useProductsQuery = (
  options: GetProductsOptions = {},
  initialData?: StrapiResponse<StrapiProduct[]>
) => {
  const queryObject = getQueryObject(options);
  const query = QueryString.stringify(queryObject);

  const products = useInfiniteQuery({
    queryKey: [PRODUCTS_QUERY_KEY, query],
    queryFn: async ({ pageParam = 1 }) =>
      getProducts({ ...options, pageParam }),
    getNextPageParam,
    initialPageParam: initialData ? 1 : undefined,
    initialData: {
      pages: [initialData],
      pageParams: [initialData ? 1 : undefined],
    },
  });
  return products;
};
