import {
  Close,
  Content,
  Overlay,
  Portal,
  Root,
  Trigger,
} from "@radix-ui/react-dialog";
import {
  CompoundComponentContext,
  useCompoundComponent,
} from "~/hooks/useCompoundComponent";
import Icon from "~/assets/icons/Icon";
import { ComponentProps } from "react";
import classNames from "classnames";
import QuestionmarkIcon from "../QuestionmarkIcon";

type DialogProps = {
  trigger: React.ReactNode;
  className?: string;
  children: React.ReactNode;
  displayIcon?: boolean;
  canBeFullscreen?: boolean;
  closeClassName?: string;
  contentClassName?: string;
} & React.ComponentProps<typeof Root>;

export function Dialog({
  trigger,
  className,
  children,
  displayIcon,
  canBeFullscreen,
  closeClassName,
  contentClassName,
  ...restProps
}: DialogProps) {
  return (
    <Root {...restProps}>
      <Trigger asChild>{trigger}</Trigger>
      <Portal>
        <Overlay className="bg-black/50 fixed inset-0 backdrop-blur-sm" />
        <Content
          className={classNames(
            "fixed flex items-center justify-center",
            canBeFullscreen
              ? "left-0 top-0 w-full max-w-full h-full max-h-full md:w-[85vw] md:max-w-3xl md:h-[85vh] md:max-h-[840px] md:top-1/2 md:left-1/2 md:-translate-x-1/2 md:-translate-y-1/2"
              : "w-[85vw] max-w-3xl h-[85vh] max-h-[840px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          )}
          // Override pointer-events: auto from Radix to remove invisible click target
          style={{ pointerEvents: "none" }}
        >
          <CompoundComponentContext.Provider value={"Dialog"}>
            <div
              className={classNames(
                "relative w-full max-h-[min(100%,_840px)] rounded-xl bg-white overflow-scroll",
                className
              )}
              // Must use pointer-events as inline style because jest claims
              // the pointer-events are inherited (from Content above)
              style={{ pointerEvents: "auto" }}
            >
              <Close
                className={classNames(
                  "absolute top-5 right-8 p-2 hover:bg-black/5 rounded-full transition-all z-10",
                  closeClassName
                )}
                aria-label="Luk dialog"
              >
                <Icon name="close" className="text-black w-6 h-6" />
              </Close>
              <div
                className={classNames(
                  "h-full overflow-hidden",
                  contentClassName
                )}
              >
                {children}
              </div>
              {displayIcon && (
                <div className="absolute top-5 left-5 bg-secondary-900 rounded-full text-white w-10 h-10 grid place-content-center">
                  <QuestionmarkIcon className="text-3xl w-9 h-9" />
                </div>
              )}
            </div>
          </CompoundComponentContext.Provider>
        </Content>
      </Portal>
    </Root>
  );
}

function DialogClose({
  children,
  className,
  ...restProps
}: {
  children: React.ReactNode;
  className?: string;
} & ComponentProps<typeof Close>) {
  useCompoundComponent("Dialog", "Close");
  return (
    <Close className={className} asChild {...restProps}>
      {children}
    </Close>
  );
}

Dialog.Close = DialogClose;
