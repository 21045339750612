import {
  ReactNode,
  createContext,
  useContext,
  useState,
  ComponentProps,
  MouseEvent,
} from "react";
import classNames from "classnames";
import Icon from "~/assets/icons/Icon";
import {
  CompoundComponentContext,
  useCompoundComponent,
} from "~/hooks/useCompoundComponent";
import Link from "next/link";
import { IconType } from "~/assets/icons/iconPaths";
import { useRouter } from "next/router";
import { getRoute } from "~/utils/getRoute";
import type { Route } from "~/routes";

type HamburgerMenuProps = {
  id: string;
  children: ReactNode;
  label: string;
  className?: string;
};
type HamburgerMenuContextContent = {
  closeMenu: () => void;
};

type Destination = Route["destination"];

export const HamburgerMenuContext =
  createContext<HamburgerMenuContextContent | null>(null);

export function HamburgerMenu({
  id,
  children,
  label,
  className,
}: HamburgerMenuProps) {
  const [open, setOpen] = useState(false);

  function openMenu() {
    document.body.style.overflow = "hidden";
    document.body.style.pointerEvents = "none";
    setOpen(true);
  }
  function closeMenu() {
    document.body.style.overflow = "";
    document.body.style.pointerEvents = "";
    setOpen(false);
  }

  return (
    <>
      <button
        className={classNames(
          "lg:hidden rounded-full inline-flex items-center justify-center text-secondary-600 outline-none hover:bg-black/5 focus:shadow-[0_0_0_2px] focus:shadow-black p-2 transition-all",
          className
        )}
        aria-label={label}
        aria-haspopup="menu"
        aria-expanded={open}
        aria-controls={id}
        onClick={openMenu}
      >
        <Icon name="menu" className="w-6 h-6" aria-hidden />
      </button>
      <CompoundComponentContext.Provider value={"HamburgerMenu"}>
        <HamburgerMenuContext.Provider value={{ closeMenu }}>
          <div
            id={id}
            role="menu"
            aria-hidden={!open}
            style={{ pointerEvents: "auto" }}
            className={classNames("lg:block", open ? "block" : "hidden")}
          >
            {children}
          </div>
        </HamburgerMenuContext.Provider>
      </CompoundComponentContext.Provider>
    </>
  );
}

HamburgerMenu.Close = ({ onClick, ...restProps }: ComponentProps<"button">) => {
  useCompoundComponent("HamburgerMenu", "Close");
  const { closeMenu } = useContext(HamburgerMenuContext) ?? {};

  return (
    <button
      onClick={(e) => {
        closeMenu?.();
        onClick?.(e);
      }}
      {...restProps}
    />
  );
};
HamburgerMenu.Link = ({
  children,
  href,
  icon,
  className,
  onClick,
  ...restProps
}: {
  children: React.ReactNode;
  href: Destination;
  icon: IconType;
  className?: string;
  onClick?: ComponentProps<"a">["onClick"];
} & ComponentProps<typeof Link>) => {
  useCompoundComponent("HamburgerMenu", "Link");
  // TODO: Fix this with proper tests (something weird about onClick on links/a tags)
  /* istanbul ignore next */
  const { closeMenu } = useContext(HamburgerMenuContext) ?? {};
  const { pathname } = useRouter();

  /* istanbul ignore next */
  function onClickHandler(e: MouseEvent<HTMLAnchorElement>) {
    closeMenu?.();
    onClick?.(e);
  }

  return (
    <Link
      href={getRoute(href)}
      className={classNames(
        "flex items-center gap-2.5 py-2.5 px-3.5 rounded-lg whitespace-nowrap text-secondary-600 hover:text-secondary-900",
        pathname === href ? "bg-white" : "",
        className
      )}
      onClick={onClickHandler}
      {...restProps}
    >
      <Icon name={icon} className="w-6 h-6 shrink-0" />
      {children}
    </Link>
  );
};
