import { useRouter } from "next/router";
import { PropsWithChildren, useEffect, useMemo } from "react";
import { useUserQuery } from "~/api/user";
import { checkValidStore } from "~/components/Backroom/FormEditStore/checkValidStore";
import { compareRoutes } from "~/layouts/layout";
import { ROUTES } from "~/routes";

export const StoreValidation = (props: PropsWithChildren) => {
  const { pathname, push } = useRouter();
  const { data } = useUserQuery();
  const isBackroom = useMemo(() => {
    const currentRoute = ROUTES.find((route) => compareRoutes(route, pathname));
    return currentRoute?.currentNavbar === "backroom";
  }, [pathname]);

  // Effect: Check if the user has a store and if the store is valid
  useEffect(() => {
    if (
      !isBackroom ||
      pathname === "/backroom/store/new" ||
      pathname === "/backroom/store/edit"
    )
      return;

    if (data?.store?.id) {
      checkValidStore(data.store.id).then(({ isValid }) => {
        if (!isValid) {
          push("/backroom/store/new");
        }
      });
    }
  }, [pathname, data?.store?.id, isBackroom]);

  // Effect: Check if the user is logged in properly, otherwise delete session
  useEffect(() => {
    if (!isBackroom || pathname === "/backroom/login") return;

    // If the user is signed in but has no store, log them out
    if (data && !data.store?.id) {
      push("/backroom/logout");
    }
  }, [pathname, data?.store?.id, isBackroom]);

  return <>{props.children}</>;
};
