import { createContext, useContext } from "react";

const LayoutContext = createContext<{
  isFullScreen: boolean;
  setIsFullScreen: (isFullScreen: boolean) => void;
}>({ isFullScreen: false, setIsFullScreen: () => null });

export const LayoutProvider = LayoutContext.Provider;

export const useLayout = () => {
  return useContext(LayoutContext);
};
