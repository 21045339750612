import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useMemo } from "react";

const initialSingleStore = { currentStoreId: null, limitedStoreId: null };
export const singleStoreAtom = atomWithStorage<{
  currentStoreId: number | null;
  limitedStoreId: number | null;
}>("singleStore", initialSingleStore);

export const useSingleStoreAtom = () => {
  const [singleStore, setSingleStore] = useAtom(singleStoreAtom);

  const isLimited = useMemo(() => {
    return Boolean(
      singleStore.currentStoreId &&
        singleStore.limitedStoreId &&
        singleStore.currentStoreId === singleStore.limitedStoreId
    );
  }, [singleStore.currentStoreId, singleStore.limitedStoreId]);

  const onVisitDifferentStore = (currentStoreId: number | null) => {
    if (
      currentStoreId &&
      singleStore.limitedStoreId &&
      currentStoreId === singleStore.limitedStoreId
    )
      return;

    setSingleStore((prev) => ({
      ...prev,
      currentStoreId,
      limitedStoreId: null,
    }));
  };

  return {
    ...singleStore,
    isLimited,
    onVisitDifferentStore,
    setSingleStore,
  };
};
