export const getAge = (born: Date, now: Date) => {
  const birthdayThisYear = new Date(
    now.getFullYear(),
    born.getMonth(),
    born.getDate()
  );

  if (now >= birthdayThisYear) return now.getFullYear() - born.getFullYear();
  else return now.getFullYear() - born.getFullYear() - 1;
};

export const getDate = (
  date: Date | number,
  options?: Intl.DateTimeFormatOptions
) => {
  return new Intl.DateTimeFormat(
    "da-DK",
    options ?? {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    }
  ).format(date);
};

export function getDay(date: Date) {
  return new Intl.DateTimeFormat("da-DK", {
    weekday: "long",
  }).format(date);
}

export function getTime(time: string | null) {
  if (!time) return "";
  return new Intl.DateTimeFormat("da-DK", {
    timeStyle: "short",
  }).format(new Date(`1900-01-01T${time}`));
}

export const convertToInputDateFormat = (dateString?: string) => {
  const date = dateString ? new Date(dateString) : new Date();

  const year = date.getFullYear();
  // Adding 1 because getMonth() returns month from 0-11
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  // Format the date in YYYY-MM-DD format
  return `${year}-${month}-${day}`;
};

export const convertInputDateToDate = (inputDate: string) => {
  const [year, month, day] = inputDate.split("-").map(Number);
  return new Date(year, month - 1, day);
};
