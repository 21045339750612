import { useState } from "react";
import { LinkButton } from "~/components/LinkButton";
import Icon from "~/assets/icons/Icon";
import { Logo } from "~/components/Logo";
import { getRoute } from "~/utils/getRoute";
import { MobileMenu } from "../MobileMenu";
import CartButton from "~/components/CartButton";
import { GroupedCategory } from "~/helpers/getGroupedCategories";
import { FavoritesLink } from "~/components/FavoritesLink";
import { useSingleStoreAtom } from "~/atoms/singleStore";

function MobileNavBar({ categories }: { categories: GroupedCategory[] }) {
  const [isOpen, setIsOpen] = useState(false);
  const { isLimited, limitedStoreId } = useSingleStoreAtom();

  const logoHref = isLimited
    ? // We can safely assume that limitedStoreId is not null here, because isLimited is true
      getRoute("/stores/:id", { id: limitedStoreId as number })
    : "/";

  return (
    <header className="flex lg:hidden justify-between items-center px-2 md:px-16 lg:px-20 py-8 h-navbar relative">
      {/*
        h-navbar is used to set the height of the navbar. It is defined in tailwind.config.js as a css variable.
        The css variable is defined in global.scss.
        This height is used in multiple places to control the height of the content.
        Example: Map height on Explore page (navbar + map = 100vh)
      */}
      <div className="flex">
        {!isLimited && (
          <>
            <LinkButton
              theme="clear"
              onClick={() => setIsOpen(true)}
              square
              aria-label="Åbn menu"
              className="w-12"
            >
              <Icon name="menu" />
            </LinkButton>
            <LinkButton
              href={getRoute("/explore")}
              theme="clear"
              square
              aria-label="Udforsk"
              className="w-12"
            >
              <Icon name="pinOutline" className="w-5 h-5" />
            </LinkButton>
          </>
        )}
      </div>
      <Logo
        size="full"
        href={logoHref}
        className="hidden sm:block absolute left-[50%] translate-x-[-50%]"
      />
      <Logo
        size="icon"
        href={logoHref}
        className="sm:hidden absolute left-[50%] translate-x-[-50%]"
      />
      <nav className="text-black ml-12">
        <div className="lg:hidden">
          <MobileMenu
            // TODO: This is temporary until we have more products
            categories={
              // istanbul ignore next
              process.env.NODE_ENV === "production" ? [] : categories
            }
            isOpen={isOpen}
            closeMenu={() => setIsOpen(false)}
          />
        </div>
        <div className="flex">
          <CartButton square />
          {!isLimited && <FavoritesLink square />}
        </div>
      </nav>
    </header>
  );
}

export default MobileNavBar;
