import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { getProducts } from "~/api/products";

export type Favorite = {
  id: number;
  type: "product";
};

export const favoritesAtom = atomWithStorage<Favorite[]>("bdFavorites", []);

export const useFavorites = () => {
  const [favorites, setFavorites] = useAtom(favoritesAtom);

  const findMatch = (favorite: Favorite) => {
    const { id, type } = favorite;
    return favorites.find((f) => f.id === id && f.type === type);
  };

  const toggleFavorite = (favorite: Favorite) => {
    const isFavorite = findMatch(favorite);

    if (isFavorite) {
      setFavorites(favorites.filter((f) => f.id !== isFavorite.id));
    } else {
      setFavorites([...favorites, favorite]);
    }
  };

  const getFavoriteProducts = async () => {
    if (!favorites.length) return [];

    const productIds = favorites
      .filter((f) => f.type === "product")
      .map((f) => f.id);

    const { data } = await getProducts({
      filters: { id: { $in: productIds } },
    });

    return data;
  };

  return {
    favorites,
    getFavoriteProducts,
    toggleFavorite,
    findMatch,
  };
};
