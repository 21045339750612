import axios from "axios";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Cart, CompressedCart } from "~/types";
import compressCart from "~/utils/compressCart";

const cartLastReadKey = "cart-last-read";
function tellOtherTabsAboutCartDate() {
  localStorage.setItem(cartLastReadKey, Date.now().toString());
}
function whenOtherTabsCartUpdates(handler: { (): void }) {
  const listener = (event: StorageEvent) => {
    if (event.key == cartLastReadKey) {
      handler();
    }
  };
  addEventListener("storage", listener);
  return () => removeEventListener("storage", listener);
}

type CartContext = {
  cart: Cart;
  compressedCart: CompressedCart;
  removeProductsFromCart: (productIds: number[]) => Promise<Cart>;
  addToCart: (productId: number, quantity?: number) => Promise<Cart>;
  updateInCart: (productId: number, quantity: number) => Promise<Cart>;
  checkIfInCart: (productId: number) => boolean;
};

export const defaultCartContextValue = {
  cart: [],
  compressedCart: { pids: [], qs: [] },
  removeProductsFromCart: (_productIds: number[]) => Promise.resolve([]),
  addToCart: (_productId: number, _quantity?: number) => Promise.resolve([]),
  updateInCart: (_productId: number, _quantity: number) => Promise.resolve([]),
  checkIfInCart: (_productId: number) => false,
};

export const CartContext = React.createContext<CartContext>(
  defaultCartContextValue
);

export function CartProvider({ children }: { children: ReactNode }) {
  const [cart, setCart] = useState<Cart>([]);
  const compressedCart = useMemo(() => compressCart(cart), [cart]);

  const addToCart = useCallback(
    async (productId: number, quantity = 1) => {
      const nextCart = [...cart];

      const existingLine = nextCart.find(
        (line) => line.productId === productId
      );
      if (existingLine) {
        existingLine.quantity += quantity;
      } else {
        nextCart.push({ productId: productId, quantity });
      }

      const response = await axios.put("/api/cart", { cart: nextCart });
      if (response.status === 200) {
        setCart(response.data);
      }

      return response.data;
    },
    [cart, setCart]
  );

  const removeProductsFromCart = useCallback(
    async (productIds: number[]) => {
      let nextCart = [...cart];

      nextCart = nextCart.filter(
        (line) => !productIds.includes(line.productId)
      );

      const response = await axios.put("/api/cart", { cart: nextCart });
      if (response.status === 200) {
        setCart(response.data);
      }

      return response.data;
    },
    [cart, setCart]
  );

  const updateInCart = useCallback(
    async (productId: number, quantity: number) => {
      const nextCart = [...cart];

      const existingLineIndex = nextCart.findIndex(
        (line) => line.productId === productId
      );
      if (existingLineIndex !== -1) {
        const existingLine = nextCart[existingLineIndex];
        existingLine.quantity = quantity;
      }

      const response = await axios.put("/api/cart", { cart: nextCart });
      if (response.status === 200) {
        setCart(response.data);
      }

      return response.data;
    },
    [cart, setCart]
  );

  const checkIfInCart = useCallback(
    (productId: number) => {
      const existingLine = cart.find((line) => line.productId === productId);
      return Boolean(existingLine);
    },
    [cart]
  );

  const value = useMemo(
    () => ({
      cart,
      compressedCart,
      removeProductsFromCart,
      addToCart,
      updateInCart,
      checkIfInCart,
    }),
    [cart]
  );

  useEffect(() => {
    axios.get("/api/cart").then((result) => {
      setCart(result.data);
    });
  }, []);
  useEffect(() => tellOtherTabsAboutCartDate(), [cart]);
  useEffect(() => {
    return whenOtherTabsCartUpdates(() => {
      axios.get("/api/cart").then((result) => {
        if (!cart || JSON.stringify(cart) != JSON.stringify(result.data)) {
          setCart(result.data);
        }
      });
    });
  }, [cart]);

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
}
