import React, { ComponentProps } from "react";
import {
  Root,
  Trigger,
  Portal,
  Overlay,
  Content,
  Title,
  Description,
  Cancel,
  Action,
} from "@radix-ui/react-alert-dialog";
import {
  CompoundComponentContext,
  useCompoundComponent,
} from "~/hooks/useCompoundComponent";
import classNames from "classnames";
import Icon from "~/assets/icons/Icon";
import { IconType } from "~/assets/icons/iconPaths";

type AlertDialogProps = {
  trigger: React.ReactNode;
  className?: string;
  children: React.ReactNode;
  iconName?: IconType;
} & React.ComponentProps<typeof Root>;
type AlertDialogContentProps = {
  title: string;
  children: React.ReactNode;
  hiddenTitle?: boolean;
} & React.ComponentProps<typeof Root>;

export function AlertDialog({
  trigger,
  children,
  iconName,
  ...restProps
}: AlertDialogProps) {
  return (
    <Root {...restProps}>
      <Trigger asChild>{trigger}</Trigger>
      <Portal>
        <Overlay className="fixed inset-0 bg-black/50 backdrop-blur-sm" />
        <Content className="fixed top-1/2 left-1/2 max-h-[85vh] w-[90vw] max-w-max -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white p-5 shadow-md focus:outline-none">
          <CompoundComponentContext.Provider value={"AlertDialog"}>
            <div
              className={`relative flex justify-center flex-wrap pt-10 ${
                iconName ? "mb-7" : "mb-0"
              }`}
            >
              <Cancel className="absolute top-0 right-0">
                <Icon name="close" className="text-secondary-600 w-6 h-6" />
              </Cancel>
              {iconName && (
                <Icon name={iconName} className="text-secondary-600 w-6 h-6" />
              )}
            </div>
            {children}
          </CompoundComponentContext.Provider>
        </Content>
      </Portal>
    </Root>
  );
}

function AlertDialogClose({
  children,
  className,
  ...restProps
}: {
  children: React.ReactNode;
  className?: string;
} & ComponentProps<typeof Cancel>) {
  useCompoundComponent("AlertDialog", "Close");
  return (
    <Cancel className={className} asChild {...restProps}>
      {children}
    </Cancel>
  );
}
function AlertDialogCancel({
  children,
  className,
  ...restProps
}: {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLButtonElement>) {
  useCompoundComponent("AlertDialog", "Cancel");
  return (
    <Cancel asChild>
      <button
        className={classNames(
          "flex justify-center gap-2 text-secondary-600 bg-secondary-100 rounded-lg py-3 px-6",
          className
        )}
        {...restProps}
      >
        <Icon name="arrowLeft" className="w-6 h-6" />
        {children}
      </button>
    </Cancel>
  );
}
function AlertDialogOk({
  children,
  className,
  ...restProps
}: {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLButtonElement>) {
  useCompoundComponent("AlertDialog", "Ok");
  return (
    <Action asChild>
      <button
        className={classNames(
          "text-white bg-secondary-900 rounded-lg py-3 px-6",
          className
        )}
        {...restProps}
      >
        {children}
      </button>
    </Action>
  );
}
function AlertDialogContent({
  title,
  children,
  hiddenTitle,
}: AlertDialogContentProps) {
  useCompoundComponent("AlertDialog", "Content");
  return (
    <>
      <Title
        className={classNames(
          "text-secondary-800 m-0 text-xl font-medium mb-5 text-center",
          hiddenTitle && "sr-only"
        )}
      >
        {title}
      </Title>
      <Description className="text-secondary-800 mb-10 text-center">
        {children}
      </Description>
    </>
  );
}

AlertDialog.Close = AlertDialogClose;
AlertDialog.Cancel = AlertDialogCancel;
AlertDialog.Ok = AlertDialogOk;
AlertDialog.Content = AlertDialogContent;
