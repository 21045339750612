/* eslint-disable camelcase */
import { validateBIC, validateIBAN } from "ibantools";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  StrapiStore,
  getZAddress,
  getZStrapiImage,
  zOpeningHours,
  zOtherStore,
  zStrapiVideo,
} from "~/shared-types";
import { convertBooleanToRadioString } from "~/utils/convertRadioBooleans";
import { validateAge } from "~/utils/validateAge";

const yesOrNo = z.union([z.literal("yes"), z.literal("no")]);

const defaultErrors = {
  required_error: "Feltet skal udfyldes",
  invalid_type_error: "Feltet er ikke udfyldt korrekt",
};

export const editStoreFormSchema = z.object({
  storeId: z.number(),

  name: z
    .string(defaultErrors)
    .min(1, { message: defaultErrors.required_error }),
  introduction: z
    .string(defaultErrors)
    .min(8, { message: "Du skal udfylde mindst 8 tegn" })
    .max(120, { message: "Du kan maks udfylde 120 tegn" }),
  description: z
    .string(defaultErrors)
    .min(8, { message: "Du skal udfylde mindst 8 tegn" }),

  storeImage: getZStrapiImage(defaultErrors),
  featuredMedia: getZStrapiImage(defaultErrors),
  coverVideo: zStrapiVideo.nullish(),
  location: z.object({ lat: z.number(), lng: z.number() }).nullish(),

  // Contact
  publicEmail: z
    .string(defaultErrors)
    .min(1, { message: defaultErrors.required_error })
    .email({ message: "Ugyldig email" }),
  publicPhone: z
    .string(defaultErrors)
    .min(1, { message: defaultErrors.required_error }),
  facebookUrl: z.string().nullish(),
  instagramUrl: z.string().nullish(),
  websiteUrl: z.string().nullish(),

  // Address
  showAddressOnMap: yesOrNo,
  acceptsPickUp: yesOrNo,
  sameAddress: yesOrNo,
  otherStores: zOtherStore.array().nullish(),
  companyAddress: getZAddress(defaultErrors),
  formattedcompanyAddress: z.string().nullish(),
  shownAddress: getZAddress(defaultErrors).nullish(),
  formattedshownAddress: z.string().nullish(),

  // Opening Hours
  monday: z.string().optional(),
  tuesday: z.string().optional(),
  wednesday: z.string().optional(),
  thursday: z.string().optional(),
  friday: z.string().optional(),
  saturday: z.string().optional(),
  sunday: z.string().optional(),
  openingHours: zOpeningHours.nullish(),
  openingHoursDescription: z.string().nullish(),

  // Delivery
  offersFreeDelivery: yesOrNo,
  deliveryPrice: z.number().max(999999).nullish(),
  freeDeliveryPurchasePrice: z.number().max(999999).nullish(),
  orderEmail: z
    .string(defaultErrors)
    .min(1, { message: defaultErrors.required_error })
    .email({ message: "Ugyldig email" }),
  deliveryType: z.enum(["selfManaged", "none", "shipmondo"]),
  shipmondoUsername: z.string().nullish(),
  shipmondoPassword: z.string().nullish(),

  // Store Owner
  cvr: z
    .string(defaultErrors)
    .min(1, { message: defaultErrors.required_error }),
  ownerFirstName: z
    .string(defaultErrors)
    .min(1, { message: defaultErrors.required_error }),
  ownerLastName: z
    .string(defaultErrors)
    .min(1, { message: defaultErrors.required_error }),
  ownerPhone: z
    .string(defaultErrors)
    .min(1, { message: defaultErrors.required_error }),
  ownerEmail: z
    .string(defaultErrors)
    .min(1, { message: defaultErrors.required_error })
    .email({ message: "Ugyldig email" }),
  ownerBirthday: z
    .string(defaultErrors)
    .min(1, { message: defaultErrors.required_error })
    .refine((date) => validateAge({ birthdayDate: date, minAge: 18 }), {
      message: "Du skal være over 18 år for at oprette en butik",
    }),

  // Store Bank Account
  bankAccountFirstName: z
    .string(defaultErrors)
    .min(1, { message: defaultErrors.required_error }),
  bankAccountLastName: z
    .string(defaultErrors)
    .min(1, { message: defaultErrors.required_error }),
  bankAccountIban: z
    .string(defaultErrors)
    .min(1, { message: defaultErrors.required_error })
    .refine((iban) => validateIBAN(iban).valid, { message: "Ugyldigt IBAN" }),
  bankAccountSwiftBic: z
    .string(defaultErrors)
    .min(1, { message: defaultErrors.required_error })
    .refine((swift) => validateBIC(swift).valid, {
      message: "Ugyldigt SWIFT/BIC",
    }),
  stripeAccountId: z.string(),
});

export type Inputs = Partial<z.infer<typeof editStoreFormSchema>>;

export const prepareDefaultValues = (store: StrapiStore) => {
  const defaultValues = { storeId: store.id } as const;

  const bankAccountIban =
    process.env.NODE_ENV !== "production"
      ? store.attributes.bankAccountIban ?? "DK5000400440116243"
      : store.attributes.bankAccountIban;

  const bankAccountSwiftBic =
    process.env.NODE_ENV !== "production"
      ? store.attributes.bankAccountSwiftBic ?? "LOSADKKK"
      : store.attributes.bankAccountSwiftBic;

  return {
    ...defaultValues,
    ...store.attributes,
    storeImage: store.attributes.storeImage.data,
    coverVideo: store.attributes.coverVideo?.data,
    featuredMedia: store.attributes.featuredMedia.data,
    acceptsPickUp: convertBooleanToRadioString(store.attributes.acceptsPickUp),
    sameAddress: convertBooleanToRadioString(store.attributes.sameAddress),
    showAddressOnMap: convertBooleanToRadioString(
      store.attributes.showAddressOnMap
    ),
    offersFreeDelivery: convertBooleanToRadioString(
      store.attributes.offersFreeDelivery
    ),
    deliveryType: store.attributes.deliveryType ?? "selfManaged",
    bankAccountIban,
    bankAccountSwiftBic,
    shipmondoUsername: store.attributes.shipmondoUsername ? "redacted" : null,
    shipmondoPassword: store.attributes.shipmondoPassword ? "redacted" : null,
  };
};

export const useStoreForm = (store: StrapiStore) => {
  return useForm<Inputs>({
    mode: "onChange",
    defaultValues: prepareDefaultValues(store),
  });
};
