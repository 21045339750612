import { AxiosInstance } from "axios";
import { StrapiResponse } from "~/shared-types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Populate = any;
type Filters = Record<string, unknown>;

export type Options = {
  pageParam?: number;
  sortKey?: string;
  sortDirection?: "asc" | "desc";
  publicationState?: "live" | "preview";
  limit?: number;
  populate?: Populate;
  filters?: Filters;
};

export type QueryFunctionOptions = Options & {
  strapiClient?: AxiosInstance;
};

export type StrapiQueryObject = {
  sort?: string;
  publicationState?: string;
  pagination?: { page?: number; limit?: number };
  populate?: Populate;
  filters?: Filters;
};

export const getQueryObject = (options: Options): StrapiQueryObject => {
  const {
    pageParam,
    sortKey,
    sortDirection = "asc",
    publicationState = "live",
    limit,
    populate,
    filters,
  } = options;

  const queryObject: StrapiQueryObject = {
    sort: sortKey ? `${sortKey}:${sortDirection}` : undefined,
    publicationState,
    pagination: { page: pageParam, limit },
    populate,
    filters,
  };

  return queryObject;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getNextPageParam = <TPageType extends StrapiResponse<any>>(
  lastPage?: TPageType
) => {
  const pagination = lastPage?.meta.pagination;
  if (!pagination) return undefined;

  if (pagination.page >= pagination.pageCount) return undefined;

  return pagination.page + 1;
};

export const EMPTY_STRAPI_RESPONSE: StrapiResponse<[]> = {
  data: [],
  meta: {},
};
