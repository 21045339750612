import { Inter } from "@next/font/google";
import { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";
import classNames from "classnames";

const inter = Inter({ subsets: ["latin"] });

export enum HTMLLevels {
  "h1" = "h1",
  "h2" = "h2",
  "h3" = "h3",
  "h4" = "h4",
  "h5" = "h5",
  "h6" = "h6",
}
type TitleProps = DetailedHTMLProps<
  HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
> & {
  level?: HTMLLevels;
  children: ReactNode;
  className?: string;
};

function Heading({
  level = HTMLLevels.h1,
  children,
  className,
  ...headingProps
}: TitleProps) {
  const Tag = level;

  return (
    <Tag className={classNames(inter.className, className)} {...headingProps}>
      {children}
    </Tag>
  );
}

export { Heading };
