import { getAge } from "./dateHandler";

type Params = {
  birthdayDate: Date | string;
  minAge?: number;
  maxAge?: number;
};

export const validateAge = ({ birthdayDate, minAge, maxAge }: Params) => {
  const birthday = new Date(birthdayDate);
  const now = new Date();

  if (minAge && getAge(birthday, now) < minAge) return false;

  if (maxAge && getAge(birthday, now) > maxAge) return false;

  return true;
};
